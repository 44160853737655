import React, { useState } from "react";
import { Button, Modal } from "@mui/material";
import { useLocation } from "react-router-dom";
import "./modal.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FunctionsIcon from "@mui/icons-material/Functions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CheckIcon from "@mui/icons-material/Check";
import { FaFacebookMessenger, FaGofore } from "react-icons/fa6";
import { Discuss } from "react-loader-spinner";

const ShareLeageModal = ({ open, setOpen, roomData }) => {
  const params = useLocation();
  console.log(params);
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
  };
  const handleClose = () => {
    setOpen(false);
    setCopied(false);
  };

  const roomId = roomData;
  const whatsAppInviteHandler = () => {
    const baseUrl = `${process.env.REACT_APP_CLIENT_URL}`;

    const invitationMessage = `LeagueID: ${roomId}`;

    const deepLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      invitationMessage
    )}`;

    window.open(deepLink, "_blank");
  };

  const gmailInviteHandler = () => {
    const invitationMessage = `LeagueID: ${roomId}`;

    const gmailDeepLink = `mailto:?subject=Join Our League&body=${encodeURIComponent(
      invitationMessage
    )}`;

    window.location.href = gmailDeepLink;
  };

  const linkedInInviteHandler = () => {
    const invitationMessage = `LeagueID: ${roomId}`;

    const linkedinDeepLink = `https://www.linkedin.com/`;

    // Open LinkedIn with the deep link
    window.open(linkedinDeepLink, "_blank");
  };

  const messengerInviteHandler = () => {
    const invitationMessage = `LeagueID: ${roomId}`;

    const facebookShareLink = "https://www.facebook.com/";

    // Open LinkedIn with the deep link
    window.open(facebookShareLink, "_blank");
  };

  return (
    <>
      <Modal
        style={{ backgroundColor: "black", opacity: 1 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="main-modal-room">
          <div className="joinroom-modal">
            <div className="joinroom-modal-image">
              <img src="/images/chat.png" alt="" />
            </div>
            <div className="joinroom-modal-copy-btn">
              <CopyToClipboard text={roomData} onCopy={handleCopy}>
                <button>
                  <b>{roomData}</b> Click to Copy
                </button>
              </CopyToClipboard>
              {copied && (
                <div className="checkicon">
                  <span>
                    <CheckIcon />
                  </span>
                </div>
              )}
            </div>
            <div className="joinroom-modal-text">
              <p>Copy the code and share with your friends</p>
            </div>
            <div className="create-league-icons">
              <button onClick={linkedInInviteHandler}>
                <div className="icons-circle">
                  <LinkedInIcon />
                </div>
              </button>
              <button onClick={gmailInviteHandler}>
                <div className="icons-circle">
                  <FaGofore style={{ fontSize: "20px" }} />
                </div>
              </button>
              <button onClick={whatsAppInviteHandler}>
                <div className="icons-circle">
                  <WhatsAppIcon />
                </div>
              </button>
              <button>
                <div onClick={messengerInviteHandler} className="icons-circle">
                  <FaFacebookMessenger style={{ fontSize: "20px" }} />
                </div>
              </button>
            </div>
            <div className="joinroom-modal-btns-create">
              <button onClick={handleClose}>Close</button>
            </div>
            {/* <div className="joinroom-modal-btns-cancel">
              <button>Cancel</button>
            </div> */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShareLeageModal;
