import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { Modal, Button, Box, Typography } from "@mui/material";
import "./advertise.css";

const AdverTiseModal = ({
  open,
  handleClose,
  setIsComplete,
  isComplete,
  isCompleteTwo,
  isCompleteThree,
  setIsCompleteTwo,
  setIsCompleteThree,
  clueCount,
  guessCount,
}) => {
  const [videoEnded, setVideoEnded] = useState(false);

  useEffect(() => {
    if (videoEnded) {
      if (clueCount === 0 && open) {
        setIsComplete(true);
        setVideoEnded(false);
      } else if (clueCount === 1 && open) {
        setIsCompleteTwo(true);
        setVideoEnded(false);
      } else if (clueCount === 2 && open) {
        setIsCompleteThree(true);
        setVideoEnded(false);
      }
    }
  }, [videoEnded]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="advertise">
        <div>
          <ReactPlayer
            playing
            width="100%"
            onEnded={() => setVideoEnded(true)}
            url="https://www.youtube.com/watch?v=lTTajzrSkCw&ab_channel=QUESSEBruno"
          />
        </div>

        <div>
          {!isComplete || !isCompleteTwo || !isCompleteThree ? (
            <p>
              Note:{" "}
              <span style={{ color: "AppWorkspace" }}>
                You have to watch the full video to access the clue
              </span>
            </p>
          ) : null}
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AdverTiseModal;
