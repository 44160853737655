//key is the guesssNumber and the value is points on perguess
export const pointsBasedonGuess = {
  2: 15,
  3: 12,
  4: 10,
  5: 8,
  6: 6,
  7: 4,
  8: 2,
  9: 1,
};
