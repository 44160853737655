import slugify from 'slugify';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import app from '../firebase.config';

export async function generateUniqueSlug(title) {
  const db = getFirestore(app);
  let slug = generateSlug(title);
  let counter = 1;

  while (true) {
    const slugQuery = query(collection(db, 'posts'), where('slug', '==', slug));
    const querySnapshot = await getDocs(slugQuery);

    if (querySnapshot.empty) {
      return slug; // Slug is unique
    }

    // If slug already exists, append a counter and generate a new slug
    slug = `${generateSlug(title)}-${counter}`;
    counter++;
  }
}

export function generateSlug(title) {
  const options = {
    lower: true,
    strict: true,
  };

  return slugify(title, options);
}
