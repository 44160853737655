import React, { useEffect, useState } from "react";
import "./league.css";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SendIcon from "@mui/icons-material/Send";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { Link } from "react-router-dom";
import { List, ListItem, ListItemText, IconButton } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SettingsIcon from "@mui/icons-material/Settings";
import { useDispatch, useSelector } from "react-redux";
import { Global_Room, Join_Room, Log_Out, MY_Room } from "../../store/actions";
import { Hourglass } from "react-loader-spinner";
import api from "../../api";

const Leagues = () => {
  const { myRoom, joinRoom, globalRoom } = useSelector((state) => state.rooms);
  const { user } = useSelector((state) => state.auth);
  const { error, isLoading, secondLoaders } = useSelector(
    (state) => state.error
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Global_Room());
    dispatch(MY_Room());
    dispatch(Join_Room());
  }, []);

  const originalArray = globalRoom?.allUsers || [];
  const sortedArray = [...originalArray].sort();
  const finalArray = sortedArray.sort((a, b) => b.totalPoint - a.totalPoint);

  const findGlobalRanking = finalArray.findIndex(
    (player) => player._id === user.id
  );

  //find general league points

  // check token
  useEffect(() => {
    const checkUser = async () => {
      const sendData = {
        refreshToken: user.refreshToken,
      };

      try {
        await api.post(`/user/userdata/checktoken`, sendData);
      } catch (err) {
        dispatch(Log_Out());
      }
    };
    if (user?.refreshToken) {
      checkUser();
    }
  }, []);
  // delete user

  return (
    <>
      {isLoading ? (
        <div className="dd-loaders">
          <Hourglass
            visible={true}
            height="80"
            width="80"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={["#5E8717", "#a7ff05"]}
          />
        </div>
      ) : (
        <div className="league">
          <div className="league-btn">
            <Link to="/guesswhofootball/createleague">
              <button>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    overflow: "hidden",
                  }}
                >
                  <span>
                    <SettingsIcon style={{ marginTop: "5px" }} />
                  </span>
                  <span>Configure Leagues</span>
                </div>
              </button>
            </Link>
          </div>

          <div className="main-league">
            <div className="main-league-container">
              <div className="classic-league">
                <div className="classic-league-logo">
                  <p>Invitational Classic League</p>
                </div>

                <div className="classic-league-contents">
                  {joinRoom.length > 0 && (
                    <div className="rank-logo">
                      <div className="king-logo">
                        <span>
                          <StarBorderPurple500Icon
                            style={{ fontSize: "28px" }}
                          />
                        </span>
                        <p>Rank</p>
                      </div>
                      <div className="king-logo">
                        <p>League</p>
                      </div>
                    </div>
                  )}
                  <div className="classic-league-list">
                    {joinRoom.length > 0 ? (
                      <>
                        {joinRoom.map((item, i) => {
                          const foundPlayer = item.members.find(
                            (member) => member._id === user.id
                          );

                          const originalArray2 = item?.members || [];
                          const sortedArray2 = [...originalArray2].sort();
                          const sortedMembers = sortedArray2.sort(
                            (a, b) => b.totalPoint - a.totalPoint
                          );
                          const rank =
                            sortedMembers.findIndex(
                              (member) => member._id === user.id
                            ) + 1;

                          return (
                            <React.Fragment key={i}>
                              <div>
                                <Link
                                  to={`/guesswhofootball/viewleague/classic/${item._id}`}
                                >
                                  <li className="classic-league-list-items">
                                    <div className="classic-league-list-left">
                                      <div className="left-icons">
                                        <span className="circle-i">
                                          <ArrowDropDownIcon
                                            style={{ fontSize: "20px" }}
                                          />
                                        </span>
                                        <p>{rank}</p>
                                      </div>
                                      <div className="league-name">
                                        <p>{item.roomName}</p>
                                      </div>
                                    </div>

                                    <div className="classic-league-list-right">
                                      <ChevronRightIcon
                                        style={{ width: "24px" }}
                                      />
                                    </div>
                                  </li>
                                </Link>
                                <hr className="league-hr" />
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </>
                    ) : (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <h3>You didn't join any league yet</h3>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="genral-league">
                <div className="classic-league-logo">
                  <p>General League</p>
                </div>

                <div className="classic-league-contents">
                  <div className="rank-logo">
                    <div className="king-logo">
                      <span>
                        <StarBorderPurple500Icon style={{ fontSize: "28px" }} />
                      </span>
                      <p>Rank</p>
                    </div>
                    <div className="king-logo">
                      <p>League</p>
                    </div>
                  </div>

                  <div className="classic-league-list">
                    <React.Fragment>
                      <div>
                        <Link to={`/guesswhofootball/viewleague/globalleague`}>
                          <li className="classic-league-list-items">
                            <div className="classic-league-list-left">
                              <div className="left-icons">
                                <span className="circle-i">
                                  <ArrowDropDownIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                </span>
                                <p>{findGlobalRanking + 1}</p>
                              </div>
                              <div className="league-name">
                                <p>Global</p>
                              </div>
                            </div>

                            <div className="classic-league-list-right">
                              <ChevronRightIcon style={{ width: "24px" }} />
                            </div>
                          </li>
                        </Link>
                        <hr className="league-hr" />
                      </div>
                    </React.Fragment>
                    <>
                      {myRoom.map((item) => {
                        const foundPlayer = item.members.find(
                          (member) => member._id === user.id
                        );

                        const originalArray2 = item?.members || [];
                        const sortedArray2 = [...originalArray2].sort();
                        const sortedMembers = sortedArray2.sort(
                          (a, b) => b.totalPoint - a.totalPoint
                        );
                        const rank =
                          sortedMembers.findIndex(
                            (member) => member._id === user.id
                          ) + 1;

                        return (
                          <React.Fragment key={item._id}>
                            <div>
                              <Link
                                to={`/guesswhofootball/viewleague/general/${item._id}`}
                              >
                                <li className="classic-league-list-items">
                                  <div className="classic-league-list-left">
                                    <div className="left-icons">
                                      <span className="circle-i">
                                        <ArrowDropDownIcon
                                          style={{ fontSize: "20px" }}
                                        />
                                      </span>
                                      <p>{rank}</p>
                                    </div>
                                    <div className="league-name">
                                      <p>{item.roomName}</p>
                                    </div>
                                  </div>

                                  <div className="classic-league-list-right">
                                    <ChevronRightIcon
                                      style={{ width: "24px" }}
                                    />
                                  </div>
                                </li>
                              </Link>
                              <hr className="league-hr" />
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Leagues;
