import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { isInMobileBrowser, isInBrowser } from "./libs/browser";

import PopUp from "./components/PopUp/PopUp";
import Navbar from "./layout/Navbar";
import Game from "./layout/Game";
import Footer from "./layout/Footer";
import LeaguesLink from "./layout/LeaguesLink";
import ScrollToTop from "./components/ScrollToTop";
import "./styles.css";
import Hero from "./components/Hero/Hero";
import Loader from "./components/Loader/Loader";
import bgImage from "./assets/field.png";
import Privacy from "./components/Privacy/Privacy";

import Ad from "./layout/Ad";
import BlogDetails from "./components/Blogs/BlogDetails";
import Admin from "./components/Admin";
import TemptGameGuide from "./layout/TemptGameGuide";
import News from "./components/Blogs/News";
import Login from "./components/login/Login";
import SignUp from "./components/signup/SignUp";
import Profile from "./components/profile/Profile";
import UpdatePassword from "./components/UpdatePassword/UpdatePassword";
import { useSelector, useDispatch } from "react-redux";

import api from "./api";
import jwtDecode from "jwt-decode";
import { Log_Out, Refresh_Token } from "./store/actions";
import MainRoom from "./components/Rooms/MainRoom";
import RoomDetails from "./components/Rooms/RoomDetails";
import InvitationalRoom from "./components/Rooms/InvitationalRoom";
import GlobalRoom from "./components/Rooms/GlobalRoom";
import NewLoginPage from "./components/login/NewLoginPage";
import NewSignUp from "./components/signup/NewSignUp";
import UpdateProfile from "./components/profile/UpdateProfile";
import ForgetPassword from "./components/ForgotPassword/ForgetPassword";
import Leagues from "./components/League/League";
import LeagueDetails from "./components/League/LeagueDetails";
import CreateLeague from "./components/League/CreateLeague";
import JoinedLeagueDetails from "./components/League/JoinedLeagueDetails";
import GlobalLeague from "./components/League/GlobalLeague";
import PrivateRoutes from "./routes/PrivateRoutes";

export default function App() {
  const location = useLocation();
  const [showOpenApp, setShowOpenApp] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  // delete user
  useEffect(() => {
    const checkUser = async () => {
      try {
        await api.get(`/user/userdata/${user.id}`);
      } catch (err) {
        dispatch(Log_Out());
      }
    };
    if (user?.id) {
      checkUser();
    }
  }, []);
  // delete user

  // check token
  useEffect(() => {
    const checkUser = async () => {
      const sendData = {
        refreshToken: user.refreshToken,
      };

      try {
        await api.post(`/user/userdata/checktoken`, sendData);
      } catch (err) {
        dispatch(Log_Out());
      }
    };
    if (user?.refreshToken) {
      checkUser();
    }
  }, []);
  // delete user

  const fetchData = async () => {
    const sendData = {
      refreshToken: user.refreshToken,
    };

    try {
      const { data } = await api.post("/user/refreshtoken", sendData);
      console.log(data);
      const myData = {
        id: user.id,
        userName: user.userName,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        image: user.image,
        token: data.token,
        refreshToken: user.refreshToken,
      };

      dispatch(Refresh_Token(myData));
    } catch (err) {
      console.log(err.response.data.message);
    }
  };

  useEffect(() => {
    if (user?.token) {
      let currentTime = new Date();
      const decodedTime = jwtDecode(user.token);
      if (decodedTime.exp * 1000 < currentTime.getTime()) {
        fetchData();
      } else {
        setInterval(() => {
          fetchData();
        }, 200000);
      }
    }
  }, [user]);

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/privacy-policy" ||
      location.pathname === "/guesswhofootball/login" ||
      location.pathname === "/guesswhofootball/signup" ||
      location.pathname === "/guesswhofootball/profile" ||
      location.pathname === "/guesswhofootball/profile/updatepassword" ||
      location.pathname === "/guesswhofootball/forgetpassword" ||
      location.pathname.startsWith("/guesswhofootball/viewleague") ||
      location.pathname === "/guesswhofootball/createleague"
    ) {
      document.body.style.backgroundImage = `url(${bgImage})`;
      document.body.style.setProperty("--overlay-opacity", 0); // was 0.85
    } else {
      document.body.style.backgroundImage = "";
      document.body.style.setProperty("--overlay-opacity", 0.81);
    }
  }, [location]);

  useEffect(() => {
    setShowOpenApp(isInMobileBrowser);

    let tmptLoader = document.getElementById("temp-loader");
    if (tmptLoader) {
      tmptLoader.parentNode.removeChild(tmptLoader);
    }

    setTimeout(() => {
      window.ezstandalone = window.ezstandalone || {};
      window.ezstandalone.cmd = window.ezstandalone.cmd || [];
      window.ezstandalone.cmd.push(function () {
        window.ezstandalone.define(105, 106, 107, 111, 112);
        window.ezstandalone.enable();
        window.ezstandalone.display();
      });
    }, 5000);
  }, []);

  return (
    <div id="app">
      <Navbar />
      <Loader />
      <ScrollToTop />
      <div
        className="vm-placement"
        data-id="64186ff0139dbe16fbf089d7"
        data-display-type="hybrid-banner"
      ></div>
      <div className="relative w-full">
        <div
          className="vm-placement my-4"
          id="vm-av"
          data-format="isvideo"
        ></div>
      </div>

      <div
        style={{
          position: "absolute",
          maxWidth: "320px",
          left: "2px",
          width: "100%",
          zIndex: -1,
        }}
      >
        <div>
          <div id="ezoic-pub-ad-placeholder-111"> </div>
        </div>
      </div>
      <Routes>
        <Route
          path="/guesswhofootball/game"
          element={
            <>
              <Game />

              <LeaguesLink />
              <div id="ezoic-pub-ad-placeholder-105"> </div>
              <div className="relative w-full">
                <div
                  className="vm-placement"
                  data-id="642675f41e85fb3356e14cf8"
                ></div>
              </div>
              <Ad className="my-4" placementId="642675d21e85fb3356e14cf6" />
            </>
          }
        >
          <Route
            path=":leagueName"
            element={
              <>
                <Game />

                <LeaguesLink />
                <div id="ezoic-pub-ad-placeholder-105"> </div>
                <div className="relative w-full">
                  <div
                    className="vm-placement"
                    data-id="642675f41e85fb3356e14cf8"
                  ></div>
                </div>
                <Ad className="my-4" placementId="642675d21e85fb3356e14cf6" />
              </>
            }
          />
        </Route>
        <Route path="/guesswhofootball/privacy-policy" element={<Privacy />} />
        <Route
          path="/guesswhofootball"
          element={
            <>
              <Game />

              <TemptGameGuide />

              <LeaguesLink />
              <div id="ezoic-pub-ad-placeholder-105"> </div>
              <Hero />
              <div className="relative w-full">
                <div
                  className="vm-placement"
                  data-id="642675f41e85fb3356e14cf8"
                ></div>
              </div>
              <Ad className="my-4" placementId="642675d21e85fb3356e14cf6" />
            </>
          }
        />
        <Route path="/guesswhofootball/blog/dashboard" element={<Admin />} />
        <Route path="/guesswhofootball/blogs/:slug" element={<BlogDetails />} />
        <Route path="/guesswhofootball/news" element={<News />} />
        <Route path="/guesswhofootball/login" element={<Login />} />
        <Route
          path="/guesswhofootball/forgetpassword"
          element={<ForgetPassword />}
        />
        <Route path="/guesswhofootball/signup" element={<SignUp />} />

        <Route
          path="/guesswhofootball/resetpassword"
          element={<UpdatePassword />}
        />
        <Route path="/" element={<PrivateRoutes />}>
          <Route path="/guesswhofootball/profile" element={<Profile />} />
          <Route
            path="/guesswhofootball/profile/updatepassword"
            element={<UpdateProfile />}
          />
          <Route path="/guesswhofootball/viewleague" element={<Leagues />} />
          <Route
            path="/guesswhofootball/viewleague/general/:leagueid"
            element={<LeagueDetails />}
          />{" "}
          <Route
            path="/guesswhofootball/viewleague/classic/:leagueid"
            element={<JoinedLeagueDetails />}
          />
          <Route
            path="/guesswhofootball/createleague"
            element={<CreateLeague />}
          />
          <Route
            path="/guesswhofootball/viewleague/globalleague"
            element={<GlobalLeague />}
          />
        </Route>
      </Routes>
      <div id="ezoic-pub-ad-placeholder-107"> </div>
      <Footer showOpenApp={showOpenApp.status} />
      <div className="black-glass"></div>
      <PopUp mobileDeviceInfo={showOpenApp} isInBrowser={isInBrowser} />
    </div>
  );
}
