import React, { useEffect, useState } from "react";
import AdminLogin from "./AdminLogin";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import app from "../../firebase.config";
import Loader from "../Loader/Loader";
import Dashboard from "../Dashboard";

export default function Admin() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const auth = getAuth(app);

  useEffect(() => {
    setIsLoading(true);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoading(false);
        setIsLoggedIn(true);
        setUser(user);
      } else {
        setIsLoading(false);
        setIsLoggedIn(false);
        setUser({});
      }
    });
  }, []);

  return isLoading ? (
    <Loader />
  ) : isLoggedIn ? (
    <Dashboard user={user} />
  ) : (
    <AdminLogin />
  );
}
