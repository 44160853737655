import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

import app from '../../firebase.config';

import { generateUniqueSlug } from '../../utils/generateSlug';

export default function useGetBlogPost() {
  const [blogs, setBlogs] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getBlogs = async () => {
    setError(null);
    setIsLoading(true);
    try {
      const db = getFirestore(app);
      const querySnapshot = await getDocs(collection(db, 'posts'));
      const docs = [];
      querySnapshot.forEach((doc) => {
        const blogData = doc.data();
        // const slug = generateUniqueSlug(blogData.title);
        docs.push({ id: doc.id, slug: doc.slug, ...blogData });
      });

      setBlogs(
        docs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      );
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);

  return { blogs, isLoading, error };
}
