import React, { useState } from "react";
import "./signup.css";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import api from "../../api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ColorRing, Discuss } from "react-loader-spinner";
const SignUp = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [input, setInput] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const onChangehandler = (event) => {
    const { name, value } = event.target;
    setInput({ ...input, [name]: value });
  };
  const onSubmithandler = async (event) => {
    event.preventDefault();

    if (input.password !== input.confirmPassword) {
      toast.error("Password didn't match!");
    } else {
      const sendData = {
        userName: input.userName,
        firstName: input.firstName,
        lastName: input.lastName,
        email: input.email,
        password: input.password,
      };

      try {
        setLoader(true);
        const { data } = await api.post("/user/signup", sendData);
        toast.success(data.message);
        navigate("/guesswhofootball/login");
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setLoader(false);
      }
    }
  };

  const onBackHandler = () => {
    navigate("/guesswhofootball");
    //navigate(-1);
  };

  return (
    <div className="signup-main">
      <div className="login-back-btn">
        <button onClick={onBackHandler}>Go BACK</button>
      </div>
      <div className="signup">
        <div className="header">
          <h2>Register Here</h2>
        </div>
        <hr />
        <div className="signup-body">
          <form onSubmit={onSubmithandler}>
            <div className="input-items">
              <input
                className="firstinput"
                value={input.userName}
                onChange={onChangehandler}
                name="userName"
                required
                type="text"
                placeholder="userName"
              />
            </div>
            <div className="input-items">
              <input
                className="firstinput"
                value={input.firstName}
                onChange={onChangehandler}
                name="firstName"
                required
                type="text"
                placeholder="FirstName"
              />
            </div>
            <div className="input-items">
              <input
                className="firstinput"
                value={input.lastName}
                onChange={onChangehandler}
                name="lastName"
                required
                type="text"
                placeholder="LastName"
              />{" "}
            </div>
            <div className="input-items">
              <input
                className="firstinput"
                value={input.email}
                onChange={onChangehandler}
                name="email"
                required
                type="email"
                placeholder="Email"
              />{" "}
            </div>
            <div className="input-items">
              <input
                className="firstinput"
                value={input.password}
                onChange={onChangehandler}
                name="password"
                required
                type="password"
                placeholder="Password"
              />{" "}
            </div>
            <div className="input-items">
              <input
                className="firstinput"
                value={input.confirmPassword}
                onChange={onChangehandler}
                name="confirmPassword"
                required
                type="password"
                placeholder="Confirm Password"
              />{" "}
            </div>
            <Button className="sub-btn" type="submit" variant="contained">
              {loader ? (
                <Discuss
                  visible={true}
                  height="35"
                  width="55"
                  ariaLabel="comment-loading"
                  wrapperStyle={{}}
                  wrapperClass="comment-wrapper"
                  color="#fff"
                  backgroundColor="#F4442E"
                />
              ) : (
                "Register"
              )}
            </Button>
          </form>
          <div className="extra-btn">
            <span>Already have an account?</span>{" "}
            <span>
              <Link to="/guesswhofootball/login">
                <Button
                  style={{ color: "#7fda56", textTransform: "capitalize" }}
                >
                  Log In
                </Button>
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
