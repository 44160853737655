import React from "react";
import "./TemptGameGuide.css";
import useGame from "../context/game";

import { ArrowDown, ArrowUp } from "../assets/Icons";

function TemptGameGuide() {
  const { guessCount } = useGame();

  if (guessCount === 1) {
    return (
      <div className="game-guide_content game-guide_hero">
        <h1>Guess Who? Daily Football Quiz Game</h1>
        <article>
          <p>
            Play Guess Who? football and find the mystery player. The player
            currently plays for a club in the big 5 leagues* across Europe.
          </p>
          <p>
            There is a silhouette image of the player if you would like to
            reveal it for your first clue.
          </p>
          <div className="main-guid-para">
            Make your best guess and attributes will be shown to give you clues
            to who the mystery player is. For example, if you guess Mohamed
            Salah...
            <div className="game-guess_player_info guide-guess_player">
              <div className="game-guess_player_box">
                <img src="/images/clubs/515.png" alt="Liverpool" />
              </div>

              <div className="game-guess_player_box">
                <img src="/images/flags/111.svg" alt="Egypt" />
              </div>

              <div className="game-guess_player_box">
                <img src="/league-img/pl.icon.png" alt="Premiere League" />
              </div>

              <div className="game-guess_player_box bg-success">FW</div>

              <div className="game-guess_player_box">
                <span>30</span> <ArrowDown />
              </div>

              <div className="game-guess_player_box">
                <span>11</span>
                <ArrowUp />
              </div>
            </div>
            … This tells you the mystery player does not play for Liverpool,
            they are not Egyptian and they do not play in the premier league.
            You also find out the player is a Forward and they are under the age
            of 30 with a higher shirt number than 11.
          </div>
          <p>
            <em>
              *The mystery player will have made at least one appearance for a
              club in The Premier League, La Liga, Serie A, Ligue 1, or The
              Bundesliga this season.
            </em>
          </p>
        </article>
      </div>
    );
  } else {
    return <></>;
  }
}

export default TemptGameGuide;
