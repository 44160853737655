import React, { useState, useRef, useEffect, memo } from "react";
import { Link, json, useLocation, useNavigate } from "react-router-dom";
import useGame from "../../context/game";
import { LEAGUES, LEAGUES_LIST } from "../../utils/constants";
import { fetchPlayersByName, fetchClubsByName } from "../../utils/data.service";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import moment from "moment";
import {
  MAX_GUESS_COUNT,
  MAX_CLUE_COUNT,
  MAX_CLUE_COUNT_BRA_INT,
} from "../../utils/constants";

import {
  CalendarRightIcon,
  CalendarLeftIcon,
  ArrowUp,
  ArrowDown,
} from "../../assets/Icons";
import "./Game.css";
import { io } from "socket.io-client";

import { getColorForLeague } from "../../utils/functions";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import hiddenEye from "../../assets/icons/hide-eye.png";
import openEye from "../../assets/icons/open-eye.png";
import AdverTiseModal from "../advertiseModal/AdverTiseModal";

import { useSelector } from "react-redux";
import api from "../../api";
import axios from "axios";
import { pointsBasedonGuess } from "../../utils/config";

let tempCurrentFocus = null;

const GameMainBox = () => {
  const { user } = useSelector((state) => state.auth);

  const {
    league,
    addToGuessList,
    guessCount,
    gameOver,
    clueCount,
    giveClue,
    setShowStats,
    mainPlayer,
    mainClub,
    lastGame,
    guessList,
    gameNo,
    lastGameNo,

    clueNation,
    clueClub,
    cluePosition,
    clueAge,
    clueShirt,
  } = useGame();

  const [style, setStyle] = useState({
    backgroundImage: `linear-gradient(
    to right,
    rgb(236, 70, 98),
    rgb(236, 70, 98),
    yellow,
    yellow,
    rgb(19, 177, 19),
    rgb(0, 153, 255),
    rgb(0, 153, 255),
    blue
  )`,
  });

  const location = useLocation();
  //state for handle user

  //state and access localstorage for modal
  const defaultvalueOne = localStorage.getItem("clues")
    ? JSON.parse(localStorage.getItem("clues"))
    : false;
  const defaultvalueTwo = localStorage.getItem("clues2")
    ? JSON.parse(localStorage.getItem("clues2"))
    : false;
  const defaultvalueThree = localStorage.getItem("clues3")
    ? JSON.parse(localStorage.getItem("clues3"))
    : false;

  const [open, setOpen] = useState(false);
  const [isComplete, setIsComplete] = useState(defaultvalueOne);
  const [isCompleteTwo, setIsCompleteTwo] = useState(defaultvalueTwo);
  const [isCompleteThree, setIsCompleteThree] = useState(defaultvalueThree);

  //state and access localstorage for modal end
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isIntLeague, setIsIntLeague] = useState(false);
  const [isClueOne, setIsClueOne] = useState(false);
  const [isClueTwo, setIsClueTwo] = useState(false);
  const [isMainImageHidden, setIsMainImageHidden] = useState(true);
  const [isGuessTheClubGame, setIsGuessTheClubGame] = useState(false);

  const [currentFocus, setCurrentFocus] = useState(-1);
  const [shouldShowsurvey, setShouldShowsurvey] = useState(true);

  const inputRef = useRef(null);

  //clue checker
  const findNation = guessList.some((item) => item.guessCheck.nation);
  const findPlayerClub = guessList.some((item) => item.guessCheck.club);
  const findPosition = guessList.some((item) => item.guessCheck.position);
  const findEqualAge = guessList.some((item) => item.guessCheck.age.equal);
  const findPlayerGroup = guessList.some((item) => item.guessCheck.group);
  const findEqualShirtNumber = guessList.some(
    (item) => item.guessCheck.shirt_no.equal
  );
  //clue checker

  //use sideeffect for popup modal
  useEffect(() => {
    if (
      isComplete &&
      open &&
      clueCount === 0 &&
      (guessCount === 4 ||
        guessCount === 5 ||
        guessCount === 6 ||
        guessCount === 7 ||
        guessCount === 8)
    ) {
      giveClue();
      setOpen(false);
      localStorage.setItem("clues", JSON.stringify(true));
    }
  }, [isComplete]);

  useEffect(() => {
    if (
      isCompleteTwo &&
      open &&
      clueCount === 1 &&
      (guessCount === 6 || guessCount === 7 || guessCount === 8)
    ) {
      giveClue();
      setOpen(false);
      localStorage.setItem("clues2", JSON.stringify(true));
    }
  }, [isCompleteTwo]);

  useEffect(() => {
    if (isCompleteThree && open && clueCount === 2 && guessCount === 8) {
      giveClue();
      setOpen(false);
      localStorage.setItem("clues3", JSON.stringify(true));
    }
  }, [isCompleteThree]);

  useEffect(() => {
    localStorage.setItem("clues", JSON.stringify(false));
    localStorage.setItem("clues2", JSON.stringify(false));
    localStorage.setItem("clues3", JSON.stringify(false));
    setIsComplete(false);
    setIsCompleteTwo(false);
    setIsCompleteThree(false);
  }, [gameOver]);

  //use sideeffect for popup modal end here

  useEffect(() => {
    if (league) {
      let _style = getColorForLeague(league.id);
      setStyle(_style);
    } else {
      setStyle({
        backgroundImage: `linear-gradient(
        to right,
        rgb(236, 70, 98),
        rgb(236, 70, 98),
        yellow,
        yellow,
        rgb(19, 177, 19),
        rgb(0, 153, 255),
        rgb(0, 153, 255),
        blue
      )`,
      });
    }
  }, [league]);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = null;
      setFilteredPlayers([]);
    }

    if (
      league.id === LEAGUES["world-cup"] ||
      league.id === LEAGUES["all_time_legends"] ||
      league.id === LEAGUES["man-united-legends"] ||
      league.id === LEAGUES["brazilian-legends"]
    ) {
      setIsIntLeague(true);
    } else {
      setIsIntLeague(false);
    }

    if (league.id === LEAGUES["guessTheClub"]) {
      setIsGuessTheClubGame(true);
      setIsMainImageHidden(false);
    }

    if (
      league.id === LEAGUES["world-cup"] ||
      league.id === LEAGUES["brasileirão"] ||
      league.id === LEAGUES["all_time_legends"] ||
      league.id === LEAGUES["guessTheClub"]
    ) {
      setIsClueOne(true);
    } else if (
      league.id === LEAGUES["man-united-legends"] ||
      league.id === LEAGUES["brazilian-legends"]
    ) {
      setIsClueTwo(true);
    } else {
      setIsClueOne(false);
    }
  }, [location, league.id]);

  useEffect(() => {
    let timeout;
    if (gameOver && !lastGame) {
      timeout = setTimeout(() => {
        setShowStats(true);
      }, 1000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [gameOver]);

  if (!isGuessTheClubGame && !mainPlayer) return;
  else if (isGuessTheClubGame && !mainClub) return;

  const handleCurrentFocus = (e) => {
    e = e || window.event;

    if (tempCurrentFocus !== null) {
      setCurrentFocus(tempCurrentFocus);
      tempCurrentFocus = null;
    }
    if (e.key === "ArrowDown" || e.code === "ArrowDown" || e.keyCode === 40) {
      e.preventDefault();
      setCurrentFocus((prev) => {
        if (prev >= -1 && prev < filteredPlayers.length - 1) {
          return prev + 1;
        } else {
          return 0;
        }
      });
    } else if (
      e.key === "ArrowUp" ||
      e.code === "ArrowUp" ||
      e.keyCode === 38
    ) {
      e.preventDefault();
      setCurrentFocus((prev) => {
        if (prev > 0 && prev <= filteredPlayers.length - 1) {
          return prev - 1;
        } else {
          return filteredPlayers.length - 1;
        }
      });
    } else if (e.key === "Enter" || e.code === "Enter" || e.keyCode === 13) {
      e.preventDefault();
      tempCurrentFocus = null;
      setCurrentFocus(-1);
      if (currentFocus < 0) {
        return;
      }
      addToGuessList(filteredPlayers[currentFocus]);
      setFilteredPlayers([]);
      inputRef.current.value = "";
    }
  };

  const handleChange = (e) => {
    const query = e.target.value;
    if (!query) return;
    if (query.length <= 1) return setFilteredPlayers([]);
    if (isGuessTheClubGame) {
      setFilteredPlayers(fetchClubsByName(query, league.id));
    } else {
      setFilteredPlayers(fetchPlayersByName(query, league.id));
    }
  };

  const handleBlur = () => {
    setShowSuggestion(false);
    setCurrentFocus(-1);
  };
  const handleFocus = () => {
    setShowSuggestion(true);
  };
  const handlePlayerSelect = (data) => () => {
    tempCurrentFocus = null;
    setCurrentFocus(-1);
    addToGuessList(data);
    setFilteredPlayers([]);
    inputRef.current.value = "";
  };

  const handleClue = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="game-main_box">
      {!gameOver && (
        <div style={{ position: "relative" }}>
          <div className="extra">
            <div>
              {isMainImageHidden ? (
                <button
                  className="game-main_player-option"
                  onClick={() => setIsMainImageHidden(false)}
                >
                  {" "}
                  <span>Show Image</span> <img src={openEye} alt="Open eye" />{" "}
                </button>
              ) : (
                <button
                  className="game-main_player-option"
                  onClick={() => setIsMainImageHidden(true)}
                >
                  {" "}
                  <span>Hide Image</span>{" "}
                  <img src={hiddenEye} alt="Hidden eye" />{" "}
                </button>
              )}
            </div>
          </div>

          {league?.id === LEAGUES["brasileirão"] ? (
            <span className="bottom_border color-brazil">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </span>
          ) : league?.id === LEAGUES["laliga"] ? (
            <span className="bottom_border color-spain">
              <span></span>
              <span></span>
              <span></span>
            </span>
          ) : league?.id === LEAGUES["bundesliga"] ? (
            <span className="bottom_border color-germany">
              <span></span>
              <span></span>
              <span></span>
            </span>
          ) : league?.id === LEAGUES["ligue-1"] ? (
            <span className="bottom_border color-france">
              <span></span>
              <span></span>
              <span></span>
            </span>
          ) : league?.id === LEAGUES["series-a"] ? (
            <span className="bottom_border color-italy">
              <span></span>
              <span></span>
              <span></span>
            </span>
          ) : (
            <span className="bottom_border" style={style}></span>
          )}
        </div>
      )}
      {isGuessTheClubGame ? (
        <MainClub hideImage={isMainImageHidden} />
      ) : (
        <MainPlayer hideImage={isMainImageHidden} />
      )}

      {!gameOver && (
        <>
          {(league?.id === LEAGUES["man-united-legends"] ||
            league.id === LEAGUES["brazilian-legends"]) &&
            clueCount <= 1 && (
              <div className={`game-clue ${guessCount <= 4 ? "d-none" : ""}`}>
                {(((guessCount === 4 ||
                  guessCount === 5 ||
                  guessCount === 6 ||
                  guessCount === 7 ||
                  guessCount === 8) &&
                  clueCount === 0) ||
                  ((guessCount === 6 || guessCount === 7 || guessCount === 8) &&
                    clueCount === 1)) && (
                  <button
                    id="clueHandler"
                    data-restart="false"
                    onClick={handleClue}
                  >
                    {clueCount === 0
                      ? "Give me a clue!"
                      : clueCount === 1
                      ? league.id === LEAGUES["man-united-legends"] ||
                        league.id === LEAGUES["brazilian-legends"]
                        ? "Give me another clue"
                        : "Show FUT rating"
                      : !findPlayerClub
                      ? "Give me another clue"
                      : "Give me another clue"}{" "}
                  </button>
                )}
              </div>
            )}

          {league?.id !== LEAGUES["man-united-legends"] &&
            league?.id !== LEAGUES["brazilian-legends"] &&
            clueCount <=
              (!isClueOne
                ? MAX_CLUE_COUNT - 1
                : MAX_CLUE_COUNT_BRA_INT - 1) && (
              <div
                className={`game-clue ${
                  clueCount === 1 && guessCount < 4 ? "d-none" : ""
                }`}
              >
                {/* onClick={handleClue} */}
                {(((guessCount === 4 ||
                  guessCount === 5 ||
                  guessCount === 6 ||
                  guessCount === 7 ||
                  guessCount === 8) &&
                  clueCount === 0) ||
                  ((guessCount === 6 || guessCount === 7 || guessCount === 8) &&
                    clueCount === 1) ||
                  (guessCount === 8 && clueCount === 2)) &&
                  (((!findPlayerClub || !findPlayerGroup) && !clueClub) ||
                    (!findNation && !clueNation) ||
                    (!findPosition && !cluePosition) ||
                    (!findEqualAge && !clueAge) ||
                    (!findEqualShirtNumber && !clueShirt)) && (
                    <button
                      id="clueHandler"
                      data-restart="false"
                      onClick={handleClue}
                    >
                      {clueCount === 0
                        ? "Give me a clue!"
                        : clueCount === 1
                        ? league.id === LEAGUES["man-united-legends"] ||
                          league.id === LEAGUES["brazilian-legends"]
                          ? "Give me another clue"
                          : "Show FUT rating"
                        : findPlayerClub
                        ? "Give me another clue"
                        : "Give me another clue"}
                    </button>
                  )}
              </div>
            )}

          <div className="game-guess_input_box">
            <input
              ref={inputRef}
              type="text"
              placeholder={`Guess ${Math.min(
                MAX_GUESS_COUNT,
                guessCount
              )} of ${MAX_GUESS_COUNT}`}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              onKeyDown={handleCurrentFocus}
            />

            {showSuggestion && (
              <PlayersList
                players={filteredPlayers}
                handlePlayerSelect={handlePlayerSelect}
                loading={loading}
                isIntLeague={isIntLeague}
                isGuessTheClubGame={isGuessTheClubGame}
                currentFocus={currentFocus}
                setCurrentFocus={setCurrentFocus}
              />
            )}
          </div>

          <AdverTiseModal
            clueCount={clueCount}
            guessCount={guessCount}
            open={open}
            handleClose={handleClose}
            isComplete={isComplete}
            isCompleteTwo={isCompleteTwo}
            isCompleteThree={isCompleteThree}
            setIsComplete={setIsComplete}
            setIsCompleteTwo={setIsCompleteTwo}
            setIsCompleteThree={setIsCompleteThree}
          />
        </>
      )}
    </div>
  );
};

export default GameMainBox;

const PlayersList = memo(
  ({
    players,
    handlePlayerSelect,
    loading,
    isIntLeague,
    isGuessTheClubGame,
    currentFocus,
    setCurrentFocus,
  }) => {
    useEffect(() => {
      let el = document.querySelector(".game-guess_autocomplete_active");

      if (el) {
        el.parentNode.scroll({
          top: el.offsetTop,
          behavior: "smooth",
        });
      }
    }, [currentFocus]);
    return (
      <div className="game-guess_autocomplete_players_wrapper">
        {loading && (
          <div className="game-guess_loader">
            <Loader />
          </div>
        )}
        {players.map((player, idx) => (
          <button
            key={player.id}
            onMouseDown={handlePlayerSelect(player)}
            onMouseEnter={() => {
              tempCurrentFocus = idx;
              setCurrentFocus(-1);
            }}
            className={`game-guess_autocomplete_player ${
              currentFocus === idx ? "game-guess_autocomplete_active" : ""
            }`}
          >
            {!isIntLeague && !isGuessTheClubGame && (
              <img src={player.clubLogo} alt={player.club_name} />
            )}
            <span>{player.name}</span>
          </button>
        ))}
      </div>
    );
  }
);

const ClueBox = memo(
  ({
    clueCount,
    clues,
    mainPlayer,
    mainClub,
    guessTheClub,
    findPlayerClub,
    findNation,
    findPlayerGroup,
    findPosition,
    findPlayerFoot,
    findEqualAge,
    findEqualShirtNumber,
    currentLeagueId,
  }) => {
    const {
      league,
      clueNation,
      setClueNation,
      clueClub,
      setClueClub,
      cluePosition,
      setCluePosition,
      clueAge,
      setClueAge,
      clueShirt,
      setClueShirt,
      cluefoot,
      setClueFoot,
      gameOver,
      guessCount,
    } = useGame();

    useEffect(() => {
      if (clueCount === 1 || clueCount === 3) {
        if (!findNation) {
          setClueNation(true);
          localStorage.setItem("clueNation", JSON.stringify(true));
        } else if (!findPlayerClub) {
          setClueClub(true);
          localStorage.setItem("clueClub", JSON.stringify(true));
        } else if (!findPosition) {
          setCluePosition(true);
          localStorage.setItem("cluePostion", JSON.stringify(true));
        } else if (!findEqualAge) {
          setClueAge(true);
          localStorage.setItem("clueAge", JSON.stringify(true));
        } else if (!findEqualShirtNumber) {
          setClueShirt(true);
          localStorage.setItem("clueShirt", JSON.stringify(true));
        } else if (!findPlayerFoot && currentLeagueId !== undefined) {
          setClueFoot(true);
          localStorage.setItem("clueFoot", JSON.stringify(true));
        }
      }
    }, [
      findNation,
      findPlayerClub,
      findPosition,
      findEqualAge,
      findEqualShirtNumber,
    ]);

    useEffect(() => {
      if (guessCount == 1) {
        setClueNation(false);
        setClueClub(false);
        setCluePosition(false);
        setClueAge(false);
        setClueShirt(false);
        setClueFoot(false);

        localStorage.removeItem("clueNation");
        localStorage.removeItem("clueClub");
        localStorage.removeItem("cluePostion");
        localStorage.removeItem("clueAge");
        localStorage.removeItem("clueShirt");
        localStorage.removeItem("clueFoot");
      }
    }, [guessCount]);

    if (clueCount === 1) {
      if (league?.id === LEAGUES["brazilian-legends"]) {
        return (
          <>
            <div
              className="game-clue_item"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <p className="game-clue_item-appearance">
                {mainPlayer.first_appearance}
              </p>
            </div>
            <div></div>
          </>
        );
      }
      return (
        <>
          <div className="game-clue_item">
            {mainPlayer ? (
              <>
                {/* {!findNation && (
                  <img src={mainPlayer.nationFlag} alt="national flag" />
                )} */}

                {!findNation ? (
                  <img
                    className="game-guess_player_box_blocks_flag-up"
                    src={mainPlayer.nationFlag}
                    alt="national flag"
                  />
                ) : !findPlayerClub ? (
                  <img
                    className={
                      gameOver
                        ? "game-guess_player_box_blocks_group_clublogo_3"
                        : "game-guess_player_box_blocks_group_clublogo_2"
                    }
                    src={mainPlayer.clubLogo}
                    alt="national flag"
                  />
                ) : !findPlayerGroup ? (
                  <span className="clue_group">{mainPlayer.group}</span>
                ) : !findPosition ? (
                  <span
                    className={
                      gameOver
                        ? "game-guess_player_box_position_3"
                        : "game-guess_player_box_position_2"
                    }
                  >
                    {mainPlayer.position}
                  </span>
                ) : !findPlayerFoot && currentLeagueId !== undefined ? (
                  <span className="clue_foot">{mainPlayer.foot}</span>
                ) : !findEqualAge ? (
                  <span className="game-guess_player_box_age">
                    {mainPlayer.age}
                  </span>
                ) : !findEqualShirtNumber ? (
                  <span className="game-guess_player_box_shirt">
                    {mainPlayer.shirt_no}
                  </span>
                ) : null}
              </>
            ) : (
              LEAGUES_LIST.map((el) => {
                if (el.id === +mainClub.league || el.id === mainClub.league) {
                  return (
                    <img
                      key={el.id}
                      src={
                        (el.id === LEAGUES["premier-league"] ||
                          el.id === LEAGUES["laliga"]) &&
                        guessTheClub
                          ? el.logoSecondary
                          : el.logo
                      }
                      alt={el.name}
                    />
                  );
                }
              })
            )}
          </div>
          <div></div>
        </>
      );
    } else if (clueCount === 2) {
      if (league?.id === LEAGUES["man-united-legends"]) {
        return (
          <>
            <div className="game-clue_item ">
              <p className="game-clue_item-appearance">
                {mainPlayer.first_appearance}
              </p>
            </div>
            {!findNation && (
              <div className="game-clue_item">
                <img src={mainPlayer.nationFlag} alt={mainPlayer.nation_name} />
              </div>
            )}
          </>
        );
      } else if (league?.id === LEAGUES["brazilian-legends"]) {
        return (
          <>
            <div
              className="game-clue_item"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <p className="game-clue_item-appearance">
                {mainPlayer.first_appearance}
              </p>
            </div>
            <div
              className="game-clue_item"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <p className="game-clue_item-appearance">
                {mainPlayer.foot.slice(0, 1)}
              </p>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="game-clue_item ">
            <p className="game-clue_item-rating-set">{clues.rating}</p>
          </div>

          {!findNation ? (
            <img
              className="game-guess_player_box_blocks_flag-up"
              src={mainPlayer.nationFlag}
              alt="national flag"
            />
          ) : !findPlayerClub ? (
            <img
              className={
                gameOver
                  ? "game-guess_player_box_blocks_group_clublogo_3"
                  : "game-guess_player_box_blocks_group_clublogo_2"
              }
              src={mainPlayer.clubLogo}
              alt="national flag"
            />
          ) : !findPlayerGroup ? (
            <span className="clue_group">{mainPlayer.group}</span>
          ) : !findPosition ? (
            <span
              className={
                gameOver
                  ? "game-guess_player_box_position_3"
                  : "game-guess_player_box_position_2"
              }
            >
              {mainPlayer.position}
            </span>
          ) : !findPlayerFoot && currentLeagueId !== undefined ? (
            <span className="clue_foot">{mainPlayer.foot}</span>
          ) : !findEqualAge ? (
            <span className="game-guess_player_box_age">{mainPlayer.age}</span>
          ) : !findEqualShirtNumber ? (
            <span className="game-guess_player_box_shirt">
              {mainPlayer.shirt_no}
            </span>
          ) : null}
        </>
      );
    } else if (clueCount >= 3) {
      return (
        <>
          <div
            className="game-clue_item"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <p className="game-clue_item-rating-set">{clues.rating}</p>

            {!findNation && clueNation && (
              <img
                className="game-guess_player_box_blocks_flag-up"
                src={mainPlayer.nationFlag}
                alt="national flag"
              />
            )}

            {!findPlayerClub && clueClub && (
              <img
                className={
                  gameOver
                    ? "game-guess_player_box_blocks_group_clublogo_3"
                    : "game-guess_player_box_blocks_group_clublogo_2"
                }
                src={mainPlayer.clubLogo}
                alt="national flag"
              />
            )}

            {!findPosition && cluePosition && (
              <span
                className={
                  gameOver
                    ? "game-guess_player_box_position_3"
                    : "game-guess_player_box_position_2"
                }
              >
                {mainPlayer.position}
              </span>
            )}

            {!findPlayerFoot && currentLeagueId !== undefined && cluefoot && (
              <span className="clue_foot">{mainPlayer.foot}</span>
            )}

            {!findEqualAge && clueAge && (
              <span className="game-guess_player_box_age">
                {mainPlayer.age}
              </span>
            )}

            {!findEqualShirtNumber && clueShirt && (
              <span className="game-guess_player_box_shirt">
                {mainPlayer.shirt_no}
              </span>
            )}

            <>
              {!findNation && !clueNation ? (
                <img
                  className="game-guess_player_box_blocks_flag-up"
                  src={mainPlayer.nationFlag}
                  alt="national flag"
                />
              ) : !findPlayerClub && !clueClub ? (
                <img
                  className={
                    gameOver
                      ? "game-guess_player_box_blocks_group_clublogo_3"
                      : "game-guess_player_box_blocks_group_clublogo_2"
                  }
                  src={mainPlayer.clubLogo}
                  alt="national flag"
                />
              ) : !findPlayerGroup ? (
                <span className="clue_group">{mainPlayer.group}</span>
              ) : !findPosition && !cluePosition ? (
                <span
                  className={
                    gameOver
                      ? "game-guess_player_box_position_3"
                      : "game-guess_player_box_position_2"
                  }
                >
                  {mainPlayer.position}
                </span>
              ) : !findPlayerFoot &&
                currentLeagueId !== undefined &&
                !cluefoot ? (
                <span className="clue_foot">{mainPlayer.foot}</span>
              ) : !findEqualAge && !clueAge ? (
                <span className="game-guess_player_box_age">
                  {mainPlayer.age}
                </span>
              ) : !findEqualShirtNumber && !clueShirt ? (
                <span className="game-guess_player_box_shirt">
                  {mainPlayer.shirt_no}
                </span>
              ) : null}
            </>
          </div>
        </>
      );
    }
    return null;
  }
);

const MainPlayer = memo(({ hideImage }) => {
  const {
    mainPlayer,
    mainClub,
    gameOver,
    gameWinLose,
    clueCount,
    guessCount,
    gameNo,
    lastGameNo,
    clues,
    guessList,
    winDistribution,
    gameEnded,
    giveClue,
    addToGuessList,
  } = useGame();

  const findNation = guessList.some((item) => item.guessCheck.nation);
  const findPlayerClub = guessList.some((item) => item.guessCheck.club);
  const findPlayerGroup = guessList.some((item) => item.guessCheck.group);
  const findPosition = guessList.some((item) => item.guessCheck.position);
  const findPlayerFoot = guessList.some((item) => item.guessCheck.foot);
  const findEqualAge = guessList.some((item) => item.guessCheck.age.equal);
  const findLeague = guessList.some((item) => item.guessCheck.league);

  const findEqualShirtNumber = guessList.some(
    (item) => item.guessCheck.shirt_no.equal
  );

  const findEqualRating = guessList.some(
    (item) => item.guessCheck.rating.equal
  );

  const findEqualFirstAppearance = guessList.some(
    (item) => item.guessCheck.first_appearance.equal
  );

  const location = useLocation();
  const [next, setNext] = useState(null);

  useEffect(() => {
    let _next = lastGameNo + 2;
    if (_next <= gameNo) {
      setNext(_next);
    } else {
      setNext(null);
    }
  }, [lastGameNo]);

  const win = winDistribution?.some((val) => val === 1);

  const currentPath = window.location.pathname;
  const parts = currentPath.split("/");
  const leagueName = parts[parts.length - 1];
  const currentLeagueId = LEAGUES[leagueName];

  const defaultPoint =
    parseInt(localStorage.getItem(`points_${currentLeagueId}`), 10) || 0;

  const defaultleagueId =
    parseInt(localStorage.getItem(`leagueId_${currentLeagueId}`), 10) || 0;

  const [points, setPoints] = useState(defaultPoint);
  const [leagueId, setLeagueId] = useState(defaultleagueId);
  const { user } = useSelector((state) => state.auth);

  const resetDailyPoints = async () => {
    try {
      await api.delete("/user/resetcurrentpoint", {
        headers: { Authorization: "Bearer " + user.token },
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (gameEnded === null && !win && leagueId == currentLeagueId) {
      localStorage.removeItem(`points_${currentLeagueId}`);
      localStorage.removeItem(`leagueId_${currentLeagueId}`);
      setPoints(0);
      setLeagueId(0);
      resetDailyPoints();
    }
  }, []);

  const updatePoint = async (getPoint) => {
    try {
      const sendData = {
        points: Number(getPoint),
        gameNumber: 1,
      };

      const { data } = await api.post("/user/updatepoint", sendData, {
        headers: { Authorization: "Bearer " + user.token },
      });
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (window.location.search === "" && next === null) {
      if (guessCount === 2 && gameOver && win && points === 0) {
        const scorePoints = pointsBasedonGuess[guessCount];

        setPoints((prevPoint) => prevPoint + scorePoints);
        setLeagueId(currentLeagueId);
        localStorage.setItem(`points_${currentLeagueId}`, scorePoints);
        localStorage.setItem(`leagueId_${currentLeagueId}`, currentLeagueId);
        updatePoint(scorePoints);
      } else if (guessCount === 3 && gameOver && win && points === 0) {
        const scorePoints = pointsBasedonGuess[guessCount];

        setPoints((prevPoint) => prevPoint + scorePoints);
        setLeagueId(currentLeagueId);
        localStorage.setItem(`points_${currentLeagueId}`, scorePoints);
        localStorage.setItem(`leagueId_${currentLeagueId}`, currentLeagueId);
        updatePoint(scorePoints);
      } else if (guessCount === 4 && gameOver && win && points === 0) {
        const scorePoints = pointsBasedonGuess[guessCount];
        setPoints((prevPoint) => prevPoint + scorePoints);
        setLeagueId(currentLeagueId);
        localStorage.setItem(`points_${currentLeagueId}`, scorePoints);
        localStorage.setItem(`leagueId_${currentLeagueId}`, currentLeagueId);
        updatePoint(scorePoints);
      } else if (guessCount === 5 && gameOver && win && points === 0) {
        const scorePoints = pointsBasedonGuess[guessCount];
        setPoints((prevPoint) => prevPoint + scorePoints);
        setLeagueId(currentLeagueId);
        localStorage.setItem(`points_${currentLeagueId}`, scorePoints);
        localStorage.setItem(`leagueId_${currentLeagueId}`, currentLeagueId);
        updatePoint(scorePoints);
      } else if (guessCount === 6 && gameOver && win && points === 0) {
        const scorePoints = pointsBasedonGuess[guessCount];
        setPoints((prevPoint) => prevPoint + scorePoints);
        setLeagueId(currentLeagueId);
        localStorage.setItem(`points_${currentLeagueId}`, scorePoints);
        localStorage.setItem(`leagueId_${currentLeagueId}`, currentLeagueId);
        updatePoint(scorePoints);
      } else if (guessCount === 7 && gameOver && win && points === 0) {
        const scorePoints = pointsBasedonGuess[guessCount];
        setPoints((prevPoint) => prevPoint + scorePoints);
        setLeagueId(currentLeagueId);
        localStorage.setItem(`points_${currentLeagueId}`, scorePoints);
        localStorage.setItem(`leagueId_${currentLeagueId}`, currentLeagueId);
        updatePoint(scorePoints);
      } else if (guessCount === 8 && gameOver && win && points === 0) {
        const scorePoints = pointsBasedonGuess[guessCount];
        setPoints((prevPoint) => prevPoint + scorePoints);
        setLeagueId(currentLeagueId);
        localStorage.setItem(`points_${currentLeagueId}`, scorePoints);
        localStorage.setItem(`leagueId_${currentLeagueId}`, currentLeagueId);
        updatePoint(scorePoints);
      } else if (guessCount === 9 && gameOver && win && points === 0) {
        const scorePoints = pointsBasedonGuess[guessCount];
        setPoints((prevPoint) => prevPoint + scorePoints);
        setLeagueId(currentLeagueId);
        localStorage.setItem(`points_${currentLeagueId}`, scorePoints);
        localStorage.setItem(`leagueId_${currentLeagueId}`, currentLeagueId);
        updatePoint(scorePoints);
      } else {
        console.log(win);
        if (gameOver && !win) {
          updatePoint(0);
        }

        setPoints(0);
        setLeagueId(0);
      }
    }
  }, [guessCount, gameOver]);

  return (
    <div className="game-main_player">
      {lastGameNo && (
        <span>
          {" "}
          <Link
            to={`?game=${gameNo && lastGameNo}`}
            className="game-time_machine yesterday-btn"
          >
            <CalendarLeftIcon />
          </Link>
        </span>
      )}

      {next && (
        <span>
          <Link
            to={next === gameNo ? location.pathname : `?game=${gameNo && next}`}
            className="game-time_machine today-btn"
          >
            <CalendarRightIcon />
          </Link>
        </span>
      )}
      {gameWinLose.win && (
        <div className="game-main_player_alert bg-primary">Great Job!</div>
      )}
      {gameWinLose.lose && (
        <div className="game-main_player_alert bg-danger">
          The player was {mainPlayer.name}
        </div>
      )}

      {hideImage && !gameOver ? (
        <div className="game-main_player_img">
          {!mainPlayer.first_appearance && (
            <>
              {findPlayerFoot && currentLeagueId !== undefined && (
                <div className="game-guess_player_box_blocks">
                  <span>{mainPlayer.foot}</span>
                </div>
              )}
            </>
          )}
          {findNation && (
            <img
              className="game-guess_player_box_blocks_flag-up"
              src={mainPlayer.nationFlag}
              alt="national flag"
            />
          )}
          <>
            {!mainPlayer.rating && !mainPlayer.first_appearance && (
              <>
                {mainPlayer.group ? (
                  <>
                    {" "}
                    {findPlayerGroup && (
                      <div className="game-guess_player_box_blocks_group">
                        <span>{mainPlayer.group}</span>
                      </div>
                    )}
                  </>
                ) : (
                  // className={`game-guess_player_box`}
                  <>
                    {" "}
                    {findPlayerClub && (
                      <img
                        className="game-guess_player_box_blocks_group_clublogo_2"
                        src={mainPlayer.clubLogo}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
          {findPosition && (
            <div className={`game-guess_player_box_position_2`}>
              <span>{mainPlayer.position}</span>
            </div>
          )}
          {!mainPlayer.rating && !mainPlayer.first_appearance && (
            <>
              {findEqualAge && (
                <div className="game-guess_player_box_age">
                  <span style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                    {mainPlayer.age}
                  </span>
                </div>
              )}
            </>
          )}
          {!mainPlayer.rating && !mainPlayer.first_appearance && (
            <>
              {findEqualShirtNumber && (
                <div className="game-guess_player_box_shirt">
                  <span>{mainPlayer.shirt_no}</span>
                </div>
              )}
            </>
          )}
          {mainPlayer.rating && findEqualRating && (
            <div className={`game-guess_player_box_ratings`}>
              <span>{mainPlayer.rating}</span>
            </div>
          )}
          {mainPlayer.first_appearance && findEqualFirstAppearance && (
            <div className={`game-guess_player_box`}>
              <span>{mainPlayer.first_appearance}</span>
            </div>
          )}
          <span className="game-main_player-hidden">?</span>{" "}
        </div>
      ) : (
        <>
          <div
            className={`game-main_player_img ${
              !gameOver && clueCount === 0
                ? "shilloute_img"
                : !gameOver && clueCount === 1
                ? "shilloute_img2"
                : !gameOver && clueCount === 2
                ? "shilloute_img3"
                : !gameOver && clueCount === 3
                ? "shilloute_img4"
                : ""
            }`}
            style={{ backgroundImage: `url(${encodeURI(mainPlayer.photo)})` }}
          ></div>
          <div className="game-clue_items_img">
            {findNation && (
              <img
                className="game-guess_player_box_blocks_flag"
                src={mainPlayer.nationFlag}
                alt="national flag"
              />
            )}

            <>
              {!mainPlayer.rating && !mainPlayer.first_appearance && (
                <>
                  {mainPlayer.group ? (
                    <>
                      {" "}
                      {findPlayerGroup && (
                        <div className="game-guess_player_box_blocks_group">
                          <span>{mainPlayer.group}</span>
                        </div>
                      )}
                    </>
                  ) : (
                    // className={`game-guess_player_box`}
                    <>
                      {" "}
                      {findPlayerClub && (
                        <img
                          className={
                            gameOver
                              ? "game-guess_player_box_blocks_group_clublogo_3"
                              : "game-guess_player_box_blocks_group_clublogo"
                          }
                          src={mainPlayer.clubLogo}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>

            {findPosition && (
              <div
                className={
                  gameOver
                    ? "game-guess_player_box_position_3"
                    : "game-guess_player_box_position"
                }
              >
                <span>{mainPlayer.position}</span>
              </div>
            )}

            {!mainPlayer.first_appearance && (
              <>
                {findPlayerFoot && currentLeagueId !== undefined && (
                  <div className="game-guess_player_box_blocks">
                    <span>{mainPlayer.foot}</span>
                  </div>
                )}
              </>
            )}

            {!mainPlayer.rating && !mainPlayer.first_appearance && (
              <>
                {findEqualAge && (
                  <div className="game-guess_player_box_age">
                    <span>{mainPlayer.age}</span>
                  </div>
                )}
              </>
            )}

            {!mainPlayer.rating && !mainPlayer.first_appearance && (
              <>
                {findEqualShirtNumber && (
                  <div className="game-guess_player_box_shirt">
                    <span style={{ paddingLeft: "7px", paddingRight: "7px" }}>
                      {mainPlayer.shirt_no}
                    </span>
                  </div>
                )}
              </>
            )}

            {mainPlayer.rating && findEqualRating && (
              <div className={`game-guess_player_box_ratings`}>
                <span>{mainPlayer.rating}</span>
              </div>
            )}

            {mainPlayer.first_appearance && findEqualFirstAppearance && (
              <div className={`game-guess_player_box`}>
                <span>{mainPlayer.first_appearance}</span>
              </div>
            )}
          </div>
        </>
      )}

      {clueCount > 0 && (
        <div className="game-clue_items">
          <ClueBox
            clueCount={clueCount}
            clues={clues}
            mainPlayer={mainPlayer}
            findPlayerClub={findPlayerClub}
            findNation={findNation}
            findPlayerGroup={findPlayerGroup}
            findPosition={findPosition}
            findPlayerFoot={findPlayerFoot}
            findEqualAge={findEqualAge}
            findEqualShirtNumber={findEqualShirtNumber}
            currentLeagueId={currentLeagueId}
          />
        </div>
      )}
    </div>
  );
});

const MainClub = memo(({ hideImage }) => {
  const { mainClub, gameOver, gameWinLose, clueCount, gameNo, lastGameNo } =
    useGame();
  const location = useLocation();
  const [next, setNext] = useState(null);
  const [totalBlocks, setTotalBlocks] = useState(170);

  useEffect(() => {
    let _next = lastGameNo + 2;
    if (_next <= gameNo) {
      setNext(_next);
    } else {
      setNext(null);
    }
  }, [lastGameNo]);

  return (
    <div className="game-main_club">
      {lastGameNo && (
        <Link
          to={`?game=${gameNo && lastGameNo}`}
          className="game-time_machine yesterday-btn"
        >
          <CalendarLeftIcon />
        </Link>
      )}
      {next && (
        <Link
          to={next === gameNo ? location.pathname : `?game=${gameNo && next}`}
          className="game-time_machine today-btn"
        >
          <CalendarRightIcon />
        </Link>
      )}
      {gameWinLose.win && (
        <div className="game-main_player_alert bg-primary">Great Job!</div>
      )}
      {gameWinLose.lose && (
        <div className="game-main_player_alert bg-danger">
          The Club was {mainClub.club_name}
        </div>
      )}
      {hideImage && !gameOver ? (
        <div className="game-main_club_img">
          {" "}
          <span className="game-main_player-hidden">?</span>{" "}
        </div>
      ) : (
        <div
          className={`game-main_club_img`}
          style={{ backgroundImage: `url(${mainClub.photo})` }}
        >
          {!gameOver && (
            <div className="blurBox_container">
              {" "}
              {new Array(totalBlocks).fill(0).map((dl, idx) => (
                <span key={idx} className="blurBox">
                  {" "}
                </span>
              ))}{" "}
            </div>
          )}
        </div>
      )}

      {clueCount > 0 && (
        <div className="game-clue_items">
          <ClueBox
            clueCount={clueCount}
            mainClub={mainClub}
            guessTheClub={true}
          />
        </div>
      )}
    </div>
  );
});

const Loader = () => {
  return <div className="spinner-ring"></div>;
};
