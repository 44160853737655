import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { unstable_HistoryRouter as Router } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import { GameProvider } from "./context/game";
import { store } from "./store";
import history from "./store/history";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Router history={history}>
    <Provider store={store}>
      <GameProvider>
        <Toaster position="bottom-center" />
        <App />
      </GameProvider>
    </Provider>
  </Router>
);
