import React, { useEffect, useState } from "react";
import "../signup/signup.css";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import api from "../../api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ColorRing, Discuss } from "react-loader-spinner";
import useGame from "../../context/game";
import { useSelector, useDispatch } from "react-redux";
import { CLEAN_STATE, Sign_In } from "../../store/actions";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";

const Login = () => {
  const [loader, setLoader] = useState(false);
  const [input, setInput] = useState({
    email: "",
    password: "",
  });
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLogInMode, setIsLogInMode] = useState(true);
  const { isLoading, error } = useSelector((state) => state.error);

  const onChangehandler = (event) => {
    const { name, value } = event.target;
    setInput({ ...input, [name]: value });
    dispatch(CLEAN_STATE());
  };
  const onSubmithandler = async (event) => {
    event.preventDefault();

    if (isLogInMode) {
      const sendData = {
        email: input.email,
        password: input.password,
      };

      dispatch(Sign_In(sendData));
    } else {
      const sendData = {
        email: input.email,
      };

      try {
        setLoader(true);
        const { data } = await api.post("/user/resetpassword", sendData);
        toast.success(data.message);
        setInput({
          email: "",
        });
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    if (error !== null) {
      toast.error(error);
    }
  }, [error]);
  const onBackHandler = () => {
    //navigate(-1);
    navigate("/guesswhofootball");
  };
  return (
    <>
      <div className="signup-main">
        <div className="login-back-btn">
          <button onClick={onBackHandler}>Go BACK</button>
        </div>

        <div className={isLogInMode ? "login " : "pass"}>
          <div className="header">
            <h2>{isLogInMode ? "Login Here" : "Forgot password?"}</h2>
          </div>
          <hr />
          <div className="signup-body">
            <form onSubmit={onSubmithandler}>
              <div className="input-items">
                <input
                  className={isLogInMode ? "firstinput" : ""}
                  value={input.email}
                  onChange={onChangehandler}
                  name="email"
                  required
                  type="email"
                  placeholder={isLogInMode ? "Email" : "type your email"}
                />{" "}
              </div>
              {isLogInMode && (
                <div className="input-items">
                  <input
                    className="firstinput"
                    value={input.password}
                    onChange={onChangehandler}
                    name="password"
                    required
                    type="password"
                    placeholder="password"
                  />{" "}
                </div>
              )}
              {isLogInMode && (
                <div className="forget-passwrapper">
                  <div className="chech-icon">
                    <Checkbox
                      onChange={(e) => console.log(e.target.checked)}
                      icon={
                        <RadioButtonUncheckedIcon
                          style={{ color: "#9c9c9c" }}
                        />
                      }
                      checkedIcon={
                        <CheckCircleIcon style={{ color: "#9c9c9c" }} />
                      }
                    />

                    <span>Remeber me</span>
                  </div>

                  <div className="forget-pass">
                    <Link to="/guesswhofootball/forgetpassword">
                      <Button>Forgot Password?</Button>
                    </Link>
                  </div>
                </div>
              )}

              <button className="sub-btn" type="submit" variant="contained">
                {loader || isLoading ? (
                  <Discuss
                    visible={true}
                    height="35"
                    width="55"
                    ariaLabel="comment-loading"
                    wrapperStyle={{}}
                    wrapperClass="comment-wrapper"
                    color="#fff"
                    backgroundColor="#F4442E"
                  />
                ) : (
                  <>{isLogInMode ? "Log In" : "Send"}</>
                )}
              </button>
            </form>

            {isLogInMode ? (
              <div className="extra-btn">
                <span>Signup to Play against your friends</span>{" "}
                <Link to="/guesswhofootball/signup">
                  <Button
                    style={{ color: "#7fda56", textTransform: "capitalize" }}
                  >
                    Sign Up
                  </Button>
                </Link>
              </div>
            ) : (
              <div className="extra-btn">
                <Button
                  style={{ color: "#9C9C9C" }}
                  onClick={() => setIsLogInMode(!isLogInMode)}
                >
                  <span style={{ color: "#7fda56" }}>Back To LogIn</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
