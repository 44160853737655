import React, { useEffect, useState } from "react";
import { Button, Modal } from "@mui/material";
import "./modal.css";
import { CLEAN_STATE, JoinTo_NewRoom } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Discuss } from "react-loader-spinner";
import toast from "react-hot-toast";
import api from "../../api";

const ModalJoinLeague = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [roomId, setRoomId] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const onChageHandler = (e) => {
    setError("");
    setRoomId(e.target.value);
  };

  const joinNewRoomHandler = async () => {
    const sendData = {
      roomId,
    };

    try {
      setLoading(true);
      await api.post("/room/joinRoom", sendData, {
        headers: { Authorization: "Bearer " + user?.token },
      });
      setLoading(false);
      setSuccess("Joined league successful");
      setOpen(false);
    } catch (err) {
      setError(err.response.data.message);

      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setError("");
    setRoomId("");
    setLoading(false);
    setSuccess("");
  };

  useEffect(() => {
    if (!open) {
      setError("");
      setRoomId("");
      setLoading(false);
      setSuccess("");
    }
  }, [open]);

  return (
    <>
      <Modal
        style={{ backgroundColor: "black", opacity: 1 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="main-modal-room">
          <div className="joinroom-modal">
            <div className="joinroom-modal-image">
              <img src="/images/Puzzle.png" alt="" />
            </div>
            <div className="joinroom-modal-input">
              <input
                required
                style={{ paddingLeft: "8px" }}
                onChange={onChageHandler}
                type="text"
                placeholder="Enter Code"
              />
            </div>
            <div className="joinroom-modal-text">
              <p>Enter Code to join new league</p>
            </div>
            <div className="joinroom-modal-btns-create">
              <button onClick={joinNewRoomHandler}>
                {loading ? (
                  <Discuss
                    visible={true}
                    height="30"
                    width="100"
                    ariaLabel="comment-loading"
                    wrapperStyle={{}}
                    wrapperClass="comment-wrapper"
                    color="#fff"
                    backgroundColor="#F4442E"
                  />
                ) : (
                  "Join"
                )}
              </button>
            </div>
            <div className="joinroom-modal-btns-cancel">
              <button onClick={handleClose}>Close</button>
            </div>
          </div>
        </div>
      </Modal>

      {error && toast.error(error)}
      {success && toast.success(success)}
    </>
  );
};

export default ModalJoinLeague;
