import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import styles from "./styles.module.css";
import app from "../../firebase.config";

export default function AdminLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const auth = getAuth(app);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.login}>
      <h1>Admin Login</h1>
      <form className={styles.login_form} onSubmit={handleSubmit}>
        <div className={styles.login_input}>
          <label>Enter Email</label>
          <input
            required
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your Email."
          />
        </div>
        <div className={styles.login_input}>
          <label>Enter Password</label>
          <input
            required
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your Password."
          />
        </div>
        {error && <div className={styles.login_error}>{error}</div>}
        <button disabled={isLoading} className={styles.Login_button}>
          {isLoading ? "Loading..." : "Login"}
        </button>
      </form>
    </div>
  );
}
