import React from 'react';
import { useParams } from 'react-router-dom';

import './BlogDetails.css';
import useGetBlogPost from './GetBlogPost';
import Loader from '../Loader/Loader';
// import BlogListCard from './BlogListCard';
import BlogBigCard from './BlogBigCard';

export default function BlogDetails() {
  const { blogs, isLoading, error } = useGetBlogPost();
  const params = useParams();
  // Decide what to render
  let content = undefined;
  if (isLoading) content = <Loader />;
  if (!isLoading && error)
    content = <div className="blog_post_error">{error}</div>;
  if (!isLoading && !error && blogs?.length === 0)
    content = (
      <div className="blog_post_error">No blog found! please add to see.</div>
    );
  if (!isLoading && !error && blogs?.length > 0) {
    const blog = blogs.find((blog) => blog.slug === params?.slug);
    const { title, author, createdAt, imgUrl, editorData } = blog || {};

    const filteredBlogs = blogs.filter((post) => post.slug !== params?.slug);

    const randomIndices = [];

    while (
      randomIndices.length < 3 &&
      randomIndices.length < filteredBlogs.length
    ) {
      const randomIndex = Math.floor(Math.random() * filteredBlogs.length);
      if (!randomIndices.includes(randomIndex)) {
        randomIndices.push(randomIndex);
      }
    }

    const suggestedPosts = randomIndices.map((index) => filteredBlogs[index]);
    content = (
      <>
        <div className="blog_detail_container container">
          <h1 className="blog_details_heading">{title}</h1>
          <span className="author_wrapper">
            <span className="author_prefix">by</span>
            <h4 className="author_name">{author}</h4>
            <span className="post_date">
              {new Date(createdAt).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </span>
          </span>
          <div>
            <img src={imgUrl} alt={title} />
          </div>
          <div
            className="blog_detail_editor"
            dangerouslySetInnerHTML={{ __html: editorData }}
          />
        </div>
        <div className="container">
          <div className="suggested_posts">
            <hr />
            <h3 className="suggested_posts_heading">Suggested Posts</h3>
            <div className="suggested_posts_list">
              {suggestedPosts.map((post) => (
                <BlogBigCard key={post.id} blog={post} />
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }

  return content;
}
