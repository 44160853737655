import api from "../../api";
import history from "../history";

export const Sign_In = (recData) => async (dispatch) => {
  try {
    dispatch({ type: "DATA_FETCHING" });
    const { data } = await api.post("/user/login", recData);
    dispatch({ type: "SIGN_IN", payload: data });
    dispatch({ type: "SUCCESS" });

    localStorage.setItem("user", JSON.stringify(data));
    history.push("/guesswhofootball");
  } catch (err) {
    dispatch({ type: "IS_ERROR", payload: err.response.data.message });
  }
};

export const Global_Room = () => async (dispatch, getState) => {
  const { user } = getState().auth;

  try {
    dispatch({ type: "DATA_FETCHING" });
    const { data } = await api.get("/room/globalroom", {
      headers: { Authorization: "Bearer " + user.token },
    });
    dispatch({ type: "GLOBAL_ROOM", payload: data.globalRoom[0] });
    dispatch({ type: "SUCCESS" });
  } catch (err) {
    dispatch({ type: "IS_ERROR", payload: err.response.data.message });
  }
};

export const MY_Room = () => async (dispatch, getState) => {
  const { user } = getState().auth;

  try {
    dispatch({ type: "DATA_FETCHING" });
    const { data } = await api.get("/room/allroom", {
      headers: { Authorization: "Bearer " + user.token },
    });
    dispatch({ type: "MY_ROOM", payload: data.room });
    dispatch({ type: "SUCCESS" });
  } catch (err) {
    dispatch({ type: "IS_ERROR", payload: err.response.data.message });
  }
};

export const Join_Room = () => async (dispatch, getState) => {
  const { user } = getState().auth;

  try {
    dispatch({ type: "DATA_FETCHING" });
    const { data } = await api.get("/room/alljoinroom", {
      headers: { Authorization: "Bearer " + user.token },
    });
    dispatch({ type: "JOIN_ROOM", payload: data.joinRoom });
    dispatch({ type: "SUCCESS" });
  } catch (err) {
    dispatch({ type: "IS_ERROR", payload: err.response.data.message });
  }
};

export const Create_NewRoom = () => async (dispatch, getState) => {
  const { user } = getState().auth;

  try {
    dispatch({ type: "CREATE_LOADS" });
    await api.post(
      "/room/createroom",
      { test: "" },
      {
        headers: { Authorization: "Bearer " + user.token },
      }
    );

    await dispatch(MY_Room());

    dispatch({ type: "SUCCESS" });
  } catch (err) {
    dispatch({ type: "IS_ERROR", payload: err.response.data.message });
  }
};

export const JoinTo_NewRoom = (recData) => async (dispatch, getState) => {
  const { user } = getState().auth;

  try {
    await api.post("/room/joinRoom", recData, {
      headers: { Authorization: "Bearer " + user.token },
    });

    await dispatch(Join_Room());

    dispatch({ type: "SUCCESS" });
  } catch (err) {
    dispatch({ type: "IS_ERROR", payload: err.response.data.message });
  }
};

export const Refresh_Token = (recData) => async (dispatch) => {
  dispatch({ type: "REFRESH_TOKEN", payload: recData });
  localStorage.setItem("user", JSON.stringify(recData));
};

export const CLEAN_STATE = () => {
  return {
    type: "CLEAN_STATE",
  };
};

export const Log_Out = () => (dispatch) => {
  localStorage.removeItem("user");
  dispatch(CLEAN_STATE());
  dispatch({ type: "LOG_OUT" });
  history.push("/guesswhofootball/login");
};
