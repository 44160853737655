export const authReducer = (state = { user: null }, action) => {
  switch (action.type) {
    case "SIGN_UP":
      return { ...state, user: action.payload };

    case "REFRESH_TOKEN":
      return {
        ...state,
        user: {
          ...state.user,
          token: action.payload.token,
          refreshToken: action.payload.refreshToken,
        },
      };

    case "SIGN_IN":
      return { ...state, user: action.payload };

    case "LOG_OUT":
      return { user: null };

    default:
      return state;
  }
};
