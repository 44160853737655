import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./reducers/authReducer";
import { errorReducer } from "./reducers/errorReducer";
import { roomReducer } from "./reducers/roomReducer";

const userData = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

const INITIL_REDUCER = {
  auth: { user: userData },
};

export const store = configureStore({
  reducer: {
    auth: authReducer,
    error: errorReducer,
    rooms: roomReducer,
  },
  preloadedState: INITIL_REDUCER,
});
