import React, { useState } from 'react';
import './News.css';
import './Blogs.css';
import BlogBigCard from './BlogBigCard';
import useGetBlogPost from './GetBlogPost';

const News = () => {
  const { blogs, isLoading, error } = useGetBlogPost();
  const [visibleBlogs, setVisibleBlogs] = useState(6);

  // Function to handle the load more button click
  const handleLoadMore = () => {
    setVisibleBlogs((prevVisibleBlogs) => prevVisibleBlogs + 6);
  };

  // Render the blog items based on the visibleBlogs state
  const renderBlogItems = () => {
    return blogs
      .slice(0, visibleBlogs)
      .map((blog) => <BlogBigCard key={blog.id} blog={blog} />);
  };

  return (
    <div className="container">
      <div className="blog-page">
        <div className="blog-grid">{renderBlogItems()}</div>
        {visibleBlogs < blogs.length && (
          <div className="load-more-button">
            <button onClick={handleLoadMore}>Load More</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default News;
