// Import the functions you need from the SDKs you need
import { getApps, initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA7OZQ9bZKagzG5TYyqh3LxmeuLhxkJbKI",
  authDomain: "guesswhofootball-23e8a.firebaseapp.com",
  projectId: "guesswhofootball-23e8a",
  storageBucket: "guesswhofootball-23e8a.appspot.com",
  messagingSenderId: "535318965946",
  appId: "1:535318965946:web:2fa9743c6a7bf720609c83",
};

// Initialize Firebase

const app = getApps.length > 0 ? getApp() : initializeApp(firebaseConfig);

export default app;
