import React, { useEffect, useState } from "react";
import "./foergetpass.css";

import EmailIcon from "@mui/icons-material/Email";
import "../signup/signup.css";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api";
import toast from "react-hot-toast";

import { Discuss } from "react-loader-spinner";

const ForgetPassword = () => {
  let navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [input, setInput] = useState("");

  const onSubmithandler = async (event) => {
    event.preventDefault();

    const sendData = {
      email: input,
    };

    try {
      setLoader(true);
      const { data } = await api.post("/user/resetpassword", sendData);
      toast.success(data.message);
      setInput("");
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setLoader(false);
    }
  };

  const onBackHandler = () => {
    navigate(-1);
  };

  return (
    <div className="signup-main">
      <div className="login-back-btn">
        <button onClick={onBackHandler}>Go BACK</button>
      </div>
      <div className="forgetpass">
        <div className="forget-pass-content">
          <div className="forget-pass-header">
            <h4>Forgot password?</h4>
            <p>Dont worry We can help you</p>
          </div>
          <form onSubmit={onSubmithandler} className="forget-pass-forms">
            <input
              onChange={(e) => setInput(e.target.value)}
              value={input}
              placeholder="type your email"
              type="email"
              required
            />
            <div className="email-icons">
              <EmailIcon style={{ color: "#12121280", fontSize: "30px" }} />
            </div>

            <button className="sub-btn" type="submit" variant="contained">
              {loader ? (
                <Discuss
                  visible={true}
                  height="35"
                  width="55"
                  ariaLabel="comment-loading"
                  wrapperStyle={{}}
                  wrapperClass="comment-wrapper"
                  color="#fff"
                  backgroundColor="#F4442E"
                />
              ) : (
                " Send"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
