import React, { useEffect, useState } from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import "./leaguedetail.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CLEAN_STATE, Global_Room } from "../../store/actions";
import ShareLeageModal from "./ShareLeageModal";
import moment from "moment";

const GlobalLeague = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const { globalRoom } = useSelector((state) => state.rooms);
  const { error, isLoading } = useSelector((state) => state.error);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CLEAN_STATE());
    dispatch(Global_Room());
  }, []);

  const onBackHandler = () => {
    navigate(-1);
  };

  const mongoDBDate = globalRoom?.time;
  const dateObject = moment(mongoDBDate);
  const formattedDate = dateObject.format("ddd DD MMM YYYY");

  const originalArray = globalRoom?.allUsers || [];
  const sortedArray = [...originalArray].sort();
  const finalArray = sortedArray.sort((a, b) => b.totalPoint - a.totalPoint);

  return (
    <div className="league-details">
      <div className="login-back-btns">
        <button onClick={onBackHandler}>Go BACK</button>
      </div>
      <div className="league-details-content">
        <div className="league-details-container">
          <div className=" league-details-header">
            <p>Global League</p>
          </div>
          <div className="league-details-userlist">
            <div className="league-details-userlist-header">
              <div className="league-details-userlist-header-left">
                <p>Rank</p>
                <p>Player</p>
                <p></p>
              </div>
              <div className="league-details-userlist-header-right">
                <p>GN</p>
                <p>Total</p>
              </div>
            </div>
            <hr className="deatils-hr" />

            <div className="league-details-userlist-alldata">
              <>
                {finalArray?.map((item, i) => (
                  <React.Fragment key={i}>
                    <div className="league-details-userlist-items">
                      <div className="league-details-userlist-items-left">
                        <p>-{i + 1}</p>
                        <p className="username"> {item.userName}</p>
                        <p></p>
                      </div>
                      <div className="league-details-userlist-items-right">
                        <p style={{ marginLeft: "10px" }}> {item.gameNumber}</p>
                        <p style={{ fontWeight: 700 }}>{item.totalPoint}</p>
                      </div>
                    </div>
                    <hr className="deatils-hr" />
                  </React.Fragment>
                ))}
              </>
            </div>
          </div>
          {/* <div className="league-details-footer">
            <p>
              Last Updated <span>{formattedDate}</span> (Local Time)
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default GlobalLeague;
