import React, { useState } from "react";
import "./createleague.css";
import { useNavigate } from "react-router-dom";
import ModalCreateLeague from "./ModalCreateLeague";
import ModalJoinLeague from "./ModalJoinLeague";
import ShareLeageModal from "./ShareLeageModal";

const CreateLeague = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const createRoomHandler = () => {
    setOpen(true);
  };
  const joinRoomHandler = () => {
    setOpen2(true);
  };

  const onBackHandler = () => {
    navigate(-1);
  };
  return (
    <div className="create-leage">
      <div className="login-back-btnss">
        <button onClick={onBackHandler}>Go BACK</button>
      </div>

      <div className="create-league-container">
        <div className="new-leage-box">
          <div className="create-league-img">
            <img src="/images/create-league.png" alt="" />
          </div>
          <div className="create-league-header">
            <p>Create New League</p>
          </div>
          <div className="create-league-text">
            <span>
              Create a custom league. Share the code with your friends to play
              against each other Create
            </span>
          </div>

          <div className="create-league-btns">
            <button onClick={createRoomHandler}>Create</button>
          </div>
        </div>
        <div className="new-leage-box">
          <div className="create-league-img">
            <img src="/images/connect-league.png" alt="" />
          </div>
          <div className="create-league-header">
            <p>Join To New League</p>
          </div>
          <div className="create-league-text">
            <span>
              Join to exisitng league. Share the code with your friends to play
              against each other Create
            </span>
          </div>{" "}
          <div className="create-league-btns">
            <button onClick={joinRoomHandler}>Join</button>
          </div>
        </div>
      </div>
      <ModalCreateLeague open={open} setOpen={setOpen} />
      <ModalJoinLeague open={open2} setOpen={setOpen2} />
    </div>
  );
};

export default CreateLeague;
