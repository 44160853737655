import React from "react";
import { Link } from "react-router-dom";

export default function BlogBigCard({ blog }) {
  const { id, title, imgUrl, author, createdAt, editorData, slug } = blog || {};

  const parser = new DOMParser();
  const doc = parser.parseFromString(editorData, "text/html");
  const post = doc.querySelector("p");

  const generateExcerpt = (content, maxLength) => {
    if (!content) return "";
    if (content.length <= maxLength) return content;
    const trimmedContent = content.substr(0, maxLength);
    const lastSpaceIndex = trimmedContent.lastIndexOf(" ");
    const excerpt = trimmedContent.substr(0, lastSpaceIndex) + "...";
    return excerpt;
  };

  const excerpt = generateExcerpt(post?.innerText, 150);

  return (
    <div className="blog_wrapper">
      <div className="blog_image_container">
        <img className="blog_image" src={imgUrl} alt="blog image" />
      </div>
      <article className="blog_description">
        <h1 className="blog_heading">
          <Link
            to={`/guesswhofootball/blogs/${slug}`}
            className="blog_heading_link"
          >
            {title}
          </Link>
        </h1>
        <span className="author_wrapper">
          <h4 className="author_name">{author}</h4>
          <span className="post_date">
            {new Date(createdAt).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </span>
        </span>
        <p className="blog_text">{excerpt}</p>
      </article>
    </div>
  );
}
