import React, { useEffect, useState } from "react";
import "../signup/signup.css";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import api from "../../api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ColorRing, Discuss } from "react-loader-spinner";
const UpdatePassword = () => {
  const [token, setToken] = useState("");

  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [input, setInput] = useState({
    password: "",
    confirmPassword: "",
  });

  const onChangehandler = (event) => {
    const { name, value } = event.target;
    setInput({ ...input, [name]: value });
  };
  const onSubmithandler = async (event) => {
    event.preventDefault();

    if (input.password !== input.confirmPassword) {
      toast.error("Password didn't match!");
    } else {
      const sendData = {
        token,
        password: input.password,
      };

      try {
        setLoader(true);
        const { data } = await api.post("/user/emailupdatepass", sendData);
        toast.success(data.message);
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setLoader(false);
        setInput({
          password: "",
          confirmPassword: "",
        });
      }
    }
  };

  useEffect(() => {
    const urlToken = window.location.search.split("=")[1];
    setToken(urlToken);
  }, [token]);

  return (
    <div className="signup-main">
      <div className="profile">
        <div className="header">
          <h2>Update Your Password</h2>
        </div>
        <hr />
        <div className="signup-body">
          <form onSubmit={onSubmithandler}>
            <div className="input-items">
              <input
                value={input.password}
                onChange={onChangehandler}
                name="password"
                required
                type="password"
                placeholder="Password"
              />{" "}
            </div>
            <div className="input-items">
              <input
                value={input.confirmPassword}
                onChange={onChangehandler}
                name="confirmPassword"
                required
                type="password"
                placeholder="Confirm Password"
              />{" "}
            </div>
            <Button type="submit" variant="contained">
              {loader ? (
                <Discuss
                  visible={true}
                  height="35"
                  width="55"
                  ariaLabel="comment-loading"
                  wrapperStyle={{}}
                  wrapperClass="comment-wrapper"
                  color="#fff"
                  backgroundColor="#F4442E"
                />
              ) : (
                "Update"
              )}
            </Button>
          </form>
          <div className="extra-btn">
            <Link to="/guesswhofootball/login">
              <Button>Back To Login</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
