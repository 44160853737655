import { useState } from "react";
import app from "../../firebase.config";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

export default function useImageUpload() {
  const [isImageUploading, setIsImageUploading] = useState(false);

  function uploadAdapter(loader) {
    setIsImageUploading(true);
    return {
      upload: () => {
        return loader.file.then((file, metadata) => {
          return new Promise((resolve, reject) => {
            const storage = getStorage(app);
            const storageRef = ref(storage, `images/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file, metadata);

            uploadTask.on(
              "state_changed",
              (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
                switch (snapshot.state) {
                  case "paused":
                    console.log("Upload is paused");
                    break;
                  case "running":
                    console.log("Upload is running");
                    break;
                }
              },
              (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                  case "storage/unauthorized":
                    reject("User doesn't have permission to access the object");
                    break;
                  case "storage/canceled":
                    reject("User canceled the upload");
                    break;
                  case "storage/unknown":
                    reject(
                      "Unknown error occurred, inspect error.serverResponse"
                    );
                    break;
                }
              },
              () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(storageRef).then((downloadURL) => {
                  resolve({ default: downloadURL });
                  setIsImageUploading(false);
                });
              }
            );
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return { uploadPlugin, isImageUploading };
}
