import React, { useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  doc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";

import useImageUpload from "./imageUploadConfig";
import app from "../../firebase.config";
import { generateUniqueSlug } from "./../../utils/generateSlug";

import "./BlogDashboard.css";
// import FeatureImage from "./FeatureImage";

export default function BlogDashboard() {
  const { uploadPlugin, isImageUploading } = useImageUpload();
  const [editorData, setEditorData] = useState("");
  const [title, setTitle] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [author, setAuthor] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const editorRef = useRef();

  function handleEditorChange(event, editor) {
    const htmlContent = editor.getData();

    setEditorData(htmlContent);
  }

  const resetForm = () => {
    setEditorData("");
    setTitle("");
  };

  // //generating unique slug
  // function generateSlug(title) {
  //   const options = {
  //     lower: true, // Convert slug to lowercase
  //     strict: true, // Replace special characters with their closest ASCII equivalents
  //   };

  //   return slugify(title, options);
  // }

  // const generateUniqueSlug = async (db, slug) => {
  //   let uniqueSlug = slug;
  //   let counter = 1;

  //   while (true) {
  //     const slugRef = doc(db, 'posts', uniqueSlug);
  //     const snapshot = await getDocs(slugRef);

  //     if (!snapshot.exists()) {
  //       // The slug doesn't exist in the database, so it's unique
  //       return uniqueSlug;
  //     }

  //     // If the slug already exists, append a counter to make it unique
  //     uniqueSlug = `${slug}-${counter}`;
  //     counter++;
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setIsLoading(true);
    const db = getFirestore(app);
    try {
      const uniqueSlug = await generateUniqueSlug(title);
      await addDoc(collection(db, "posts"), {
        editorData,
        title,
        author,
        imgUrl,
        createdAt: new Date(Date.now()).toISOString(),
        slug: uniqueSlug,
      });
      setIsLoading(false);
      resetForm();
      navigate("/#news");
    } catch (e) {
      setIsLoading(true);
      setError(e);
    }
  };

  const handleSignOut = () => {
    const auth = getAuth(app);
    signOut(auth);
  };

  return (
    <main>
      <div className="editor_container container">
        <div className="editor_flex">
          <h2>Customize your post</h2>{" "}
          <button onClick={handleSignOut}>Logout</button>
        </div>

        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="title" className="post_title_label">
              Post Title
            </label>
            <input
              className="post_title_input"
              required
              id="title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="image_uploader">
            <label htmlFor="title" className="post_title_label">
              Featured Image
            </label>
            {/* <FeatureImage setImgUrl={setImgUrl} /> */}
            <h3>Or paste a link here :</h3>
            <input
              required
              id="title"
              type="text"
              value={imgUrl}
              onChange={(e) => setImgUrl(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="author" className="post_title_label">
              Post Author
            </label>
            <input
              className="post_title_input"
              required
              id="author"
              type="text"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
            />
          </div>
          <CKEditor
            editor={ClassicEditor}
            data={editorData}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              console.log("Editor is ready to use!", editor);
            }}
            onChange={handleEditorChange}
            config={editorConfig}
            // config={{
            //   extraPlugins: [uploadPlugin],
            // }}
            onBlur={(event, editor) => {
              // console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              // console.log("Focus.", editor);
            }}
            ref={editorRef}
          />
          {error && <div className="dashboard_error">{error?.message}</div>}
          <button disabled={isLoading || isImageUploading}>
            {isLoading
              ? "Loading..."
              : isImageUploading
              ? "Image Uploading..."
              : "Submit"}
          </button>
        </form>
      </div>
    </main>
  );
}

const editorConfig = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "|",
    "blockQuote",
    "undo",
    "redo",
    "imageTextAlternative",
    "|",
    "imageStyle:alignLeft",
    "imageStyle:full",
    "imageStyle:alignRight",
  ],
  image: {
    toolbar: [
      "imageTextAlternative",
      "|",
      "imageStyle:alignLeft",
      "imageStyle:full",
      "imageStyle:alignRight",
    ],
    styles: ["full", "alignLeft", "alignRight"],
  },
};
