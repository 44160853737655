import React, { useState } from "react";
import "./profile.css";
import { Button, dividerClasses } from "@mui/material";
import { Link } from "react-router-dom";
import api from "../../api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ColorRing, Discuss } from "react-loader-spinner";
import { useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";

const UpdateProfile = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [loader, setLoader] = useState(false);
  const [viewPass, setViewPass] = useState(false);
  const [viewPass2, setViewPass2] = useState(false);
  const [viewPass3, setViewPass3] = useState(false);

  const [input, setInput] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const onChangehandler = (event) => {
    const { name, value } = event.target;
    setInput({ ...input, [name]: value });
  };
  const onSubmithandler = async (event) => {
    event.preventDefault();

    const sendData = {
      oldPassword: input.oldPassword,
      newPassword: input.newPassword,
    };

    if (input.newPassword !== input.confirmPassword) {
      toast.error("Password Don't Match");
    } else {
      try {
        setLoader(true);
        const { data } = await api.post(
          `/user/updateprofile/${user.id}`,
          sendData
        );

        toast.success("Profile update Successfull");

        setInput({
          ...input,
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });

        navigate(-1);
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setLoader(false);
      }
    }
  };

  const onBackHandler = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="login-back-btns">
        <button onClick={onBackHandler}>Go BACK</button>
      </div>
      <div className="profiles">
        {/* <div className="signup-main">
        <div className="profile">
          <div className="header">
            <h2>Update Your Profile</h2>
          </div>
          <hr />
          <div className="signup-body">
            <form onSubmit={onSubmithandler}>
              <div className="input-items">
                <input
                  value={input.firstName}
                  onChange={onChangehandler}
                  name="firstName"
                  required
                  type="text"
                  placeholder="First name"
                />{" "}
              </div>
              <div className="input-items">
                <input
                  value={input.lastName}
                  onChange={onChangehandler}
                  name="lastName"
                  required
                  type="text"
                  placeholder="Last name"
                />
              </div>
              <div className="input-items">
                <input value={input.email} />
              </div>
              <div className="input-items">
                <input
                  value={input.oldPassword}
                  onChange={onChangehandler}
                  name="oldPassword"
                  type="password"
                  placeholder="Old Password"
                />{" "}
              </div>
              <div className="input-items">
                <input
                  value={input.newPassword}
                  onChange={onChangehandler}
                  name="newPassword"
                  type="password"
                  placeholder="New Password"
                />{" "}
              </div>
              <Button className="sub-btn" type="submit" variant="contained">
                {loader ? (
                  <Discuss
                    visible={true}
                    height="35"
                    width="55"
                    ariaLabel="comment-loading"
                    wrapperStyle={{}}
                    wrapperClass="comment-wrapper"
                    color="#fff"
                    backgroundColor="#F4442E"
                  />
                ) : (
                  "Update"
                )}
              </Button>
            </form>
          </div>
        </div>
      </div> */}
        <div className="profile-content">
          <div className="p-header">
            <h3>Update Profile</h3>
          </div>

          <form onSubmit={onSubmithandler} className="profile-forms">
            <div className="form-items">
              <label htmlFor="">Old Password</label>
              <input
                value={input.oldPassword}
                onChange={onChangehandler}
                name="oldPassword"
                type={!viewPass ? "password" : "text"}
                placeholder="Old Password"
              />
              <div className="eye-icons">
                <button type="button" onClick={() => setViewPass(!viewPass)}>
                  {!viewPass ? <VisibilityIcon /> : <CloseIcon />}
                </button>
              </div>
            </div>
            <div className="form-items">
              <label htmlFor="">New Password</label>
              <input
                value={input.newPassword}
                onChange={onChangehandler}
                name="newPassword"
                type={!viewPass2 ? "password" : "text"}
                placeholder="New Password"
              />
              <div className="eye-icons">
                <button type="button" onClick={() => setViewPass2(!viewPass2)}>
                  {!viewPass2 ? <VisibilityIcon /> : <CloseIcon />}
                </button>
              </div>
            </div>{" "}
            <div className="form-items">
              <label htmlFor="">Confirm Password</label>
              <input
                value={input.confirmPassword}
                onChange={onChangehandler}
                name="confirmPassword"
                type={!viewPass3 ? "password" : "text"}
                placeholder="Confirm Password"
              />
              <div className="eye-icons">
                <button type="button" onClick={() => setViewPass3(!viewPass3)}>
                  {!viewPass3 ? <VisibilityIcon /> : <CloseIcon />}
                </button>
              </div>
            </div>
            <div className="profile-updatepass">
              <button type="submit">
                {loader ? (
                  <Discuss
                    visible={true}
                    height="25"
                    width="60"
                    ariaLabel="comment-loading"
                    wrapperStyle={{}}
                    wrapperClass="comment-wrapper"
                    color="#fff"
                    backgroundColor="#F4442E"
                  />
                ) : (
                  "Update Password"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateProfile;
