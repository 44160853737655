import React from "react";
import Modal from "@mui/material/Modal";
import "./developmodal.css";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const DevelopMoodModal = ({ open }) => {
  const handleClose = () => {};
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="developmodal">
          <h1>
            Currently we are updating our solutions. Please try our others games
          </h1>

          <a href="/">
            <Button className="develop-btn">Go Back</Button>
          </a>
        </div>
      </Modal>
    </>
  );
};

export default DevelopMoodModal;
