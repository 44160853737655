import React, { useEffect, useRef, useState } from "react";
import "./profile.css";
import { Button, dividerClasses } from "@mui/material";
import { Link } from "react-router-dom";
import api from "../../api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ColorRing, Discuss } from "react-loader-spinner";
import { useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";

const Profile = () => {
  const { user } = useSelector((state) => state.auth);

  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const imageRef = useRef();

  const [input, setInput] = useState({
    userName: user.userName,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    oldPassword: "",
    newPassword: "",
  });

  const onChangehandler = (event) => {
    const { name, value } = event.target;
    setInput({ ...input, [name]: value });
  };
  const onSubmithandler = async (event) => {
    event.preventDefault();

    const sendData = {
      userName: input.userName,
      firstName: input.firstName,
      lastName: input.lastName,
      oldPassword: input.oldPassword,
      newPassword: input.newPassword,
    };

    const formData = new FormData();
    formData.append("userName", input.userName);
    formData.append("firstName", input.firstName);
    formData.append("lastName", input.lastName);
    formData.append("oldPassword", input.oldPassword);
    formData.append("newPassword", input.newPassword);
    formData.append("image", file);

    try {
      setLoader(true);
      const { data } = await api.post(
        `/user/updateprofile/${user.id}`,
        formData
      );

      toast.success("Profile update Successfull");

      const storeData = {
        id: user.id,
        userName: data.userName,
        firstName: data.firstName,
        lastName: data.lastName,
        email: user.email,
        token: user.token,
        image: data.image,
        refreshToken: user.refreshToken,
      };

      localStorage.setItem("user", JSON.stringify(storeData));
      setInput({
        ...input,
        oldPassword: "",
        newPassword: "",
      });
      setFile("");
    } catch (err) {
      toast.error(err.response.data.message);
      console.log(err.response.data.message);
    } finally {
      setLoader(false);
    }
  };

  const onImageHandler = () => {
    imageRef.current.click();
  };

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setImageUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  return (
    <div className="profiles">
      {/* <div className="signup-main">
        <div className="profile">
          <div className="header">
            <h2>Update Your Profile</h2>
          </div>
          <hr />
          <div className="signup-body">
            <form onSubmit={onSubmithandler}>
              <div className="input-items">
                <input
                  value={input.firstName}
                  onChange={onChangehandler}
                  name="firstName"
                  required
                  type="text"
                  placeholder="First name"
                />{" "}
              </div>
              <div className="input-items">
                <input
                  value={input.lastName}
                  onChange={onChangehandler}
                  name="lastName"
                  required
                  type="text"
                  placeholder="Last name"
                />
              </div>
              <div className="input-items">
                <input value={input.email} />
              </div>
              <div className="input-items">
                <input
                  value={input.oldPassword}
                  onChange={onChangehandler}
                  name="oldPassword"
                  type="password"
                  placeholder="Old Password"
                />{" "}
              </div>
              <div className="input-items">
                <input
                  value={input.newPassword}
                  onChange={onChangehandler}
                  name="newPassword"
                  type="password"
                  placeholder="New Password"
                />{" "}
              </div>
              <Button className="sub-btn" type="submit" variant="contained">
                {loader ? (
                  <Discuss
                    visible={true}
                    height="35"
                    width="55"
                    ariaLabel="comment-loading"
                    wrapperStyle={{}}
                    wrapperClass="comment-wrapper"
                    color="#fff"
                    backgroundColor="#F4442E"
                  />
                ) : (
                  "Update"
                )}
              </Button>
            </form>
          </div>
        </div>
      </div> */}
      <div className="profile-content">
        <div className="p-header">
          <h3>My Profile</h3>
        </div>

        <div className="p-header-icon">
          <div onClick={onImageHandler} className="p-header-circle">
            {user.image && !imageUrl && (
              <>
                <img
                  src={`${process.env.REACT_APP_SERVER_URL}${user.image}`}
                  alt=""
                  className="profile-image"
                />
              </>
            )}
            {imageUrl && (
              <>
                <img src={imageUrl} alt="" className="profile-image" />
              </>
            )}

            {!imageUrl && !user.image && (
              <>
                <PersonIcon
                  style={{
                    color: "#777777",
                    fontSize: "33px",
                    cursor: "pointer",
                  }}
                />
              </>
            )}

            <input
              ref={imageRef}
              onChange={(e) => setFile(e.target.files[0])}
              type="file"
              style={{ display: "none" }}
              accept=".png, .jpeg, .jpg"
            />
          </div>
          <div className="p-heafer-user">
            <span id="title">{input.firstName}</span>
            <span>{input.lastName}</span>
          </div>
        </div>

        <form onSubmit={onSubmithandler} className="profile-forms">
          <div className="form-items">
            <label htmlFor="">UserName</label>
            <input value={input.userName} name="userName" type="text" />
          </div>
          <div className="form-items">
            <label htmlFor="">First Name</label>
            <input
              value={input.firstName}
              onChange={onChangehandler}
              name="firstName"
              type="text"
            />
          </div>
          <div className="form-items">
            <label htmlFor="">Last Name</label>
            <input
              value={input.lastName}
              onChange={onChangehandler}
              name="lastName"
              required
              type="text"
              placeholder="Last name"
            />
          </div>
          <div className="form-items">
            <label htmlFor="">Email</label>
            <input type="email" value={input.email} />
          </div>
          <div className="form-items">
            <label htmlFor="">Password</label>
            <input value="easin12345" type="password" />
          </div>

          <div className="profile-btn">
            <button clas type="submit">
              {loader ? (
                <Discuss
                  visible={true}
                  height="25"
                  width="60"
                  ariaLabel="comment-loading"
                  wrapperStyle={{}}
                  wrapperClass="comment-wrapper"
                  color="#fff"
                  backgroundColor="#F4442E"
                />
              ) : (
                "Update"
              )}
            </button>
          </div>
        </form>

        <div className="profile-forgetpass">
          <u>
            {" "}
            <Link to="/guesswhofootball/profile/updatepassword">
              Update Password
            </Link>
          </u>
        </div>
      </div>
    </div>
  );
};

export default Profile;
