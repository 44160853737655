export const roomReducer = (
  state = { myRoom: [], joinRoom: [], globalRoom: [] },
  action
) => {
  switch (action.type) {
    case "MY_ROOM":
      return { ...state, myRoom: action.payload };
    case "JOIN_ROOM":
      return { ...state, joinRoom: action.payload };
    case "GLOBAL_ROOM":
      return { ...state, globalRoom: action.payload };

    default:
      return state;
  }
};
