import React, { useState } from "react";
import { Button, Modal } from "@mui/material";
import "./modal.css";
import api from "../../api";
import { useSelector } from "react-redux";
import ShareLeageModal from "./ShareLeageModal";
import toast from "react-hot-toast";
import { Discuss } from "react-loader-spinner";

const ModalCreateLeague = ({ open, setOpen }) => {
  const { user } = useSelector((state) => state.auth);
  console.log(user);

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [leageName, setLeagueName] = useState("");
  const [open3, setOpen3] = useState(false);
  const [roomData, setRoomdata] = useState("");
  console.log(error);
  const createNewRoomHandler = async () => {
    const sendData = {
      roomName: leageName,
    };

    try {
      setLoader(true);
      const { data } = await api.post("/room/createroom", sendData, {
        headers: {
          Authorization: "Bearer " + user?.token,
        },
      });
      setRoomdata(data.newRoom.roomId);
      setOpen3(true);
      toast.success(data.message);
      setLoader(false);
      handleClose();
    } catch (err) {
      setError(err.response.data.message);

      setLoader(false);
    }
  };
  const onChangeHandler = (e) => {
    setError("");
    setLeagueName(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setError("");
  };

  return (
    <>
      <>{error && toast.error(error)}</>
      <Modal
        style={{ backgroundColor: "black", opacity: 1 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="main-modal-room">
          <div className="joinroom-modal">
            <div className="joinroom-modal-image">
              <img src="/images/chat.png" alt="" />
            </div>
            <div className="joinroom-modal-input">
              <input
                style={{ paddingLeft: "8px" }}
                required
                onChange={onChangeHandler}
                type="text"
                placeholder="Enter league name"
              />
            </div>
            <div className="joinroom-modal-btns-create">
              <button onClick={createNewRoomHandler}>
                {loader ? (
                  <Discuss
                    visible={true}
                    height="35"
                    width="55"
                    ariaLabel="comment-loading"
                    wrapperStyle={{}}
                    wrapperClass="comment-wrapper"
                    color="#fff"
                    backgroundColor="#F4442E"
                  />
                ) : (
                  "Create"
                )}
              </button>
            </div>
            <div className="joinroom-modal-btns-cancel">
              <button onClick={handleClose}>Cancel</button>
            </div>
          </div>
        </div>
      </Modal>
      <ShareLeageModal open={open3} setOpen={setOpen3} roomData={roomData} />
    </>
  );
};

export default ModalCreateLeague;
